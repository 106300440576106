.artOptions {
    text-align: center;
}

.artHeader {
    text-align: center;
    font-size: 16px;
    margin: 3px;
}

.active-art-header {
    font-weight: bold;
}

.graphic-heading {
    font-size: 18px;
    font-family: $ub-font-slate-std;
}

.art-options-body {
    color: $ub-grey--600;
    font-size: 11px;
    font-family: $ub-font-slate-std;
}

.art-card {
    box-shadow: 0px 3px 6px $ub-button-shadow;

    &:hover {
        box-shadow: 3px 5px 6px $ub-button-shadow;
    }
}

.class-pointer {
    cursor: pointer;
}

.file-upload-card {
    display: flex;
    justify-content: space-around;
    vertical-align: center;
    flex-flow: row wrap;
}