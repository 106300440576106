.option-heading__title {
  //font-size: 16px;
  margin: 0;
  font-size: 22px;
  font-family: Slate-std-med;
  //font-weight: Bold !important;
  color: black;
  margin-top: 15px;
}

.option-heading__description {
  font-size: 12px;
  line-height: 18px;
  margin-top: 1em;
  margin-bottom: 0px;
}

.option-heading__back-btn {
  width: 30px;
  height: 34px;
}

.option-heading__close{
  visibility: hidden;
}

.option-heading__tablet_discription{
  font-size: 12px;
  display: none; 
}

.option-heading__mobile_discription{
  font-size: 12px;
  display: none;
}

@media screen and (max-width: $bp-desktop){
  .option-heading {
    width: 100%; 
    height: 50px;
    background-color: $ub-nav-bgcl;
    padding-top: 20px;    
  }

  .option-heading__title {
    margin-top: 5px;
  }

  .option-heading__back-btn {
    background-color: $ub-nav-bgcl;
    border: none; 
  }

  .option-heading__close {
    visibility: visible; 
    padding-right: 10px; 
    padding-left: 5px; 
    padding-bottom: 5px;
    text-shadow: none; 
  }

  .option-heading__description {
    display: none;
  }

  .option-heading__tablet_discription{
    display: inherit; 
  }

}

.option-heading__help-bubble-container {
  /*display: flex;
  justify-content: space-between;
  align-items: center;*/
}


@media screen and (max-width: $bp-mobile){
    
  .option-heading__mobile_discription {
    display: inherit;
  }

  .option-heading__tablet_discription{
    display: none; 
  }
}