/* ==========================================================================
  Small / Mobile / Default
   ========================================================================== */

.swatches {
  line-height: 0;
}

.swatches__name {
  font-size: 11px;
}

.swatches__swatch {
  margin: 3px;
}

.add-note-button {
  @extend .button--light;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  span {
    padding-left: 10px;
  }
}