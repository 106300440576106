.tab {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  background-color: $ub-white; 
  opacity: 1;
  position: static;
  width: 100%; 
  height: calc(100vh - 112px);
  transition: none;
  //This is what is causing the CSS tooltips to break on the tab, yet it fixes the scroll bug... -Logan
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px 25px 5px 10px;
}

.tab__header {
  height: 80px;
}

.tab__nav {
  background-color: purple;
  flex: 1 0 20%;
}

.tab__title {
  background-color: lightskyblue;
  flex: 1 0 80%;
}

.tab__actions--mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 16px;
  z-index: 9999;
  width: 100%;
  background-color: $ub-white;
}

.tab__actions--mobile button {
  height: 46px;
}

.option-body {
  height: 90%;
  width: 100%;
  overflow-x: visible;
}



@media screen and (max-width: $bp-desktop){


  .tab {
    position: absolute;
    bottom:0;
    right: 0; 
    padding: 0; 
    height: 100%; 
    z-index: 100;
    transition: all .15s linear;
  }

  .tab__hide{
    position: absolute;
    bottom: 0; 
    height: 1px; 
    visibility: hidden;
  }

  .option-body { 
    max-width: 400px;
    align-self: center; 
    margin-top: 40px; 
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    padding: 0px 15px; 
  }
}
