.empty-state {
  background-color: $ub-grey--100;
  border: 1px solid $ub-grey--200;
  border-radius: 6px;
  padding: 25px 15px;
  margin: 0px 0px 16px 0px;
}

.empty-state__title {
  font-size: 16px;
  margin-bottom: 2em;
}

.empty-state__message p {
  font-size: 12px;
  margin-bottom: 2em;
  line-height: 1.5em;
  &:last-of-type {
    margin-bottom: 0;
  }
}