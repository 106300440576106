.ColorDropdown {
  >button {
    height: 39px;
    width: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: solid 2px rgb(194, 194, 194);
    .arrow-box {
      position: absolute;
      background-color: rgb(194, 194, 194);
      height: 10px;
      width: 12px;
      bottom: 0px;
      right: 0px;
    }
    &::after {
      position: absolute;
      bottom: 3px;
      right: 2px;
      color: grey;
    }
  }
  .dropdown-menu {
    width: 350px;
    padding: 30;
    margin: 0;
    .item-container{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(35px, 1fr));
      justify-content: center;
      align-items: center;
      width: 90%;
      padding: 0; 
      margin: 0;
      margin-left: 15px;
      .color-item{
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 4px;
        svg {
          position: absolute;
        }
        .color-box {
          border: 2px solid rgb(170, 170, 170);
        }
        &:hover {
          outline: 2px solid $ub-hover-color;
          outline-offset: -4px;
        }
      }
    }
  }
}