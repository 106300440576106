.ImageUploadModal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  text-align: center;
  .image-preview-box {
    display: flex;
    justify-content: center;
    background-color: $ub-preview-card-bg;
    border: 1px solid $ub-preview-card-border;
    border-radius: 1px;
    width: 220px;
    height: 180px;
    img {
      max-width: 220px;
      max-height: 180px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-right: 5px;
    .title-container {
      .sub-title {
      margin-top: 20px;
        font-size: 16px;
      }
      .message {
        margin-top: 5px;
        font-size: 12px;
      }
    }
    .actions {
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        opacity: 1;
      }
      .number-input{
        width: 40%;
        height: 36.5px;
        border: 1px solid #d2d2d2;
        text-align: center;
      }
      button{
        width: 40%;
      }
    }
    .not-sure {
      margin-top: 20px;
      // align-self: flex-end;
      width: 100%;
    }

    .image-loading-button {
      pointer-events: none;
      background-color: black;
      border: 1px solid #007BFF;
    }

  }
  .message {
    margin: 10px 0px;
    grid-column: 1 / -1;
    font-size: 11px;
  }
}

@media screen and (max-width: $bp-mobile){
  .ImageUploadModal{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}
