.not-found-404 {
  margin-top: 80px;
  h1, p {
    background-color: $ub-black;
    color: $ub-white;
    padding: 8px;
    letter-spacing: 0.25em;
  }
  .ghost-wrapper {
    width: 128px;
    height: 200px;
    margin-top: 30px;
    position: relative;
    .ghost {
      width: 100%;
      animation: float 2s ease-out infinite;
      position: absolute;
      top: 0;
      left: 0;
    }
    .ghost--shadow {
      position: absolute;
      width: 100%;
      height: 7%;
      background: $ub-grey--200;
      left: 0;
      top: 100%;
      border-radius: 50%;
      animation: scale 2s infinite;
    }
  }
}