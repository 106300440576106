@import './variables';

/* ==========================================================================
   Global styles that will be applied to various aspects of the app.
   ========================================================================== */

html, * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  font-family: $ub-font-family;
  font-weight: $ub-font-weight;
  font-size: $ub-font-size;
  color: $ub-font-color;
}

.disabled {
  cursor: inherit;
  pointer-events: none;
}

.btn,
.clickable {
  cursor: pointer;

  &disabled,
  &.disabled {
    cursor: inherit;
    pointer-events: none;
  }
}

.fillxy,
.fillx {
  width: 86%;
}
.fillxy,
.filly {
  height: 100%;
}

.scroll-x,
.scroll-xy {
  overflow-x: auto;
}
.scroll,
.scroll-y,
.scroll-xy {
  overflow-y: auto;
}

.decoration-label {
  font-family: $ub-font-slate-std;
  color: $ub-tab-underline;
  font-size: 14px;
  margin: 0px;
  
}

.label-padding-top{
 padding: 17px 0px 0px 0px;
}

.flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

.class-pointer{
  cursor: pointer;
}


// .vspacer-xs // TODO: add responsive resizing
@mixin spacer-hv($width: false, $height: false) {
  @if $width != false { width: $width; }
  @if $height != false { height: $height; }
}
.hspacer,
.hspacer-15 {
  @include spacer-hv(15px, false);
}
.vspacer,
.vspacer-15 {
  @include spacer-hv(false, 15px);
}
.hspacer-30 {
  @include spacer-hv(30px, false);
}
.vspacer-30 {
  @include spacer-hv(false, 30px);
}



.text-small {
  font-size: 0.75em;
}
  
.image-size--auto {
  width: 100%;
  height: auto;
}

.center-text {
  text-align: center;
}

.btn {
  border-radius: 0;
}

.btn-primary {
  background-color: $ub-black;
  &:hover {
    background-color: $ub-white;
    color: $ub-black;
    border-color: $ub-black;
  }

  &.btn-round,
  &.btn-outline {
    border-color: $ub-grey--100;
    color: $ub-grey--700;
    &:hover {
      background-color: $ub-grey--500;
      border-color: $ub-grey--500;
    }
  }
}

.btn-loading {
  color: white;
  background-color: $ub-black;
  &:hover {
    background-color: $ub-black;
    color: $ub-black;
    border-color: $ub-black;
  }

  &.btn-round,
  &.btn-outline {
    border-color: $ub-grey--100;
    color: $ub-grey--700;
    &:hover {
      background-color: $ub-grey--500;
      border-color: $ub-grey--500;
    }
  }
}

.btn-default {
  background-color: $ub-white;
  &:hover {
    background-color: $ub-grey--200;
    color: $ub-black;
    border-color: $ub-black;
  }

  &.btn-round,
  &.btn-outline {
    border-color: $ub-grey--300;
    color: $ub-grey--700;
    &:hover {
      background-color: $ub-grey--500;
      border-color: $ub-grey--200;
    }
  }
}

.btn-outline-primary {
  border-color: $ub-grey--100;
  color: $ub-grey--700;
  &:hover {
    background-color: $ub-grey--500;
    border-color: $ub-grey--500;
  }
}

.btn-outline-default {
  border-color: $ub-grey--100;
  color: $ub-grey--700;
  &:hover {
    background-color: $ub-grey--200;
    border-color: $ub-grey--200;
  }
}

.btn-round {
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.btn-colorswatch,
.btn-color-swatch {
  @extend .btn;
  margin: 0;
  padding: 0;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1px;
  border: 1px solid transparent;
  z-index: 10;
  box-sizing: border-box;

  .color-swatch-icon {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &.light-color {
    border: 1px solid #DCDCDC;
  }

  &.multi-color {
    width: 50px;
    border: 1px solid #DCDCDC;
    display: flex;
    flex-direction: row;


    &.multi-color-3 {
      width: 62px;
    }
    &.multi-color-4 {
      width: 62px;
    }
  }

  &.selected {
    border: 1px solid #0277BD;    // TODO: use variable.scss $selectedBorderColor
    box-shadow: 0 0 1px 1px #0277BD;
    // z-index: 2;
  }
}

.custom-black-button {
  width: 100%;
  height: 35px;
  text-align: center;
  color: white;
  background-color: black;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  transition: .15s;
  cursor: pointer;
}

.custom-black-button:hover {
  background-color: $darkGrey;
  transition: .15s;
}

.custom-white-button {
  width: 75%;
  height: 35px;
  text-align: center;
  background-color: white;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid $ub-grey--200;
  border-radius: 5px;
  opacity: 1;
  font-family: $ub-font-slate-bold;
  font-size: 20px;
  box-shadow: 0px 3px 6px $ub-button-shadow;
}

.custom-white-button:hover {
  background-color: white;
  transition: .15s;
}

.custom-disabled-button {
  width: 100%;
  height: 35px;
  text-align: center;
  color: #333333;
  background-color: #EBEBE4;
  border: 1px solid #D2D2D2;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  pointer-events: none;
  align-items: center;
  transition: .15s;
  cursor: pointer;
}

.atc-disabled {
  svg{
    fill: $founderTextPlaceholder !important; 
    cursor: not-allowed; 
  }
}

.custom-disabled-button-atc {
  width: 100%;
  height: 35px;
  text-align: center;
  color: #333333;
  background-color: #EBEBE4;
  border: 1px solid #D2D2D2;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .15s;
  cursor: not-allowed; 
}

.button--light {
  @extend .btn;
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: $ub-white;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $ub-grey--200;

  &:hover {
    background: $ub-grey--100;
  }
}

.button--dark {
  @extend .button--light;
  border: none;
  background: $ub-black;
  color: $ub-white;

  &:hover {
    background: $darkGrey;
    color: $ub-white;
  }
}

.modal-title {
  margin-left: 15%; 
}

.modal-title.locked {
  margin-left: 20%; 
}
.modal-footer {
  justify-content: normal;
}
.modal-content{
  border-radius: 0rem; 
}

.modal-header {
  text-align: center; 
  padding: .5rem; 
  background-color: black; 
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  color: white; 
  button {
    span {
      color: white;
    } 
  }
  button:hover {
    span {
      color: lightgrey; 
    }
  }
}

.modal-header.locked {
  .modal-title {
    margin-left: 30%; 
  }
}

/* The snackbar - position it at the bottom and in the middle of the screen */
.snackbar {
  visibility: hidden;
  min-width: 300px; /* Set a default minimum width */
  margin-left: -150px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
  button {
    span {
      color: white;
    }
  }
  button:hover {
    span {
      color: lightgrey;
    }
  }
}

.message-box{
  display: flex; 
  justify-content: center; 
  text-align: center; 
  border: solid #d2d2d2 1px; 
  color: #303030; 
  line-height: 1.5em;
  margin-top: 15px;
  padding: 16px 12px; 
  align-items: center;
  flex-flow: column;
}

.box--bordered {
  height: auto;
  padding: 18px 28px;
  margin-top: 15px;
  border: 1px solid $ub-grey--200;
  font-size: 12px;
  line-height: 1.5em;
}

.text-value-input-container {
  width: 100%;
  height: auto;
}

.custom-text-input {
  width: 100%;
  height: 35px;
  font-size: 16px;
  border: 1px solid #D2D2D2;
  text-align: center;
  color: #303030;
}

// .custom-text-input::-webkit-inner-spin-button, 
// .custom-text-input::-webkit-outer-spin-button { 
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//     margin: 0; 
// }
.append-border{
  border-radius: 7px;
  border: 1px solid #d2d2d2;
  border-left: none;
}
.text-height-number-input {
  padding-top: 7px;
  font-size: 14px;
  font-family: $ub-font-slate-std;
  font-weight: bold !important;
  color: #303030;
  width: 100%;
  height: 36.5px;
  border-radius: 7px;
  border: 1px solid #d2d2d2;
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: left;
  padding-left: 6px;
}

.text-height-number-input-text-value {
  padding-top: 7px;
  font-size: 24px;
  font-family: $ub-font-slate-std;
  font-weight: bold !important;
  color: #303030;
  width: 100%;
  height: 36.5px;
  border-radius: 7px;
  border: 1px solid #d2d2d2;
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: center;
  // padding-left: 6px;
}

// .text-height-number-input-text-value::-webkit-inner-spin-button, 
// .text-height-number-input-text-value::-webkit-outer-spin-button { 
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//     margin: 0; 
// }

.drop-down-margin-top {
  margin-top: 5px
}

/* Animations to fade the snackbar in and out */

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}


// moved from deprecated textColorSelector scss
// NOTE: we probably don't need both fade-in and fadein above.
@keyframes fade-in {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}
