.test-page.test-products-page {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  .main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
  }

  .no-results-warning {
    font-size: 10px;
    font-style: italic;
    margin: 100px auto;
    color: $ub-grey--700;
  }

  .loading-view {
    font-size: 10px;
    margin: 100px auto;
    color: $ub-grey--700;
  }

  .main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 15px;
  }

  .header-title {
  }

  .header-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .header-filter-menu {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .header-filter-menu-item {
      margin: 0 0 0 15px;

      &.active {
        font-weight: bold;
        margin: -5px -10px -5px 5px;
        padding: 5px 10px 5px 10px;
        background-color: fade_out($ub-selected-bg-color, 0.2);
        border: 1px solid $ub-selected-border-color;
        border-radius: 4px;
      }
    }
  }

  .header-search-area {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    align-items: flex-end;

    .header-search-input {
      width: 250px;
      input[type=search] {
        padding: 0 5px;
        text-align: center;
      }
    }
  }

  .main-pagination-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 15px;
  }

  .pagination-area {
    .page-link {
      color: $founderTextBlack;
      background-color: fade_out(#ffffff, 0.2);
      // border-color: $linkBlue;

      &:hover {
        color: $ub-hover-color;
        background-color: $ub-hover-selected-bg-color;
        border-color: $ub-hover-selected-bg-color;
      }

      &:focus {
        box-shadow: inherit;
      }
    }

    .page-item.active .page-link {
      z-index: 10;
      color: $founderTextBlack;
      background-color: $ub-selected-bg-color;
      border-color: $ub-selected-border-color;

      &:hover {
        color: #0056b3;
        background-color: $ub-hover-selected-bg-color;
        border-color: $ub-hover-selected-bg-color;
      }
    }
  }

  .product-count-text {
    font-size: 0.9rem;
  }

  .product-list {
    margin: 15px 0;
  }

  .card {
  }

  .card-body {
  }

  .card-text {
  }

  .card-list {
  }

  .card-item-box {
  }

  .card-margin{
    margin: 8px !important;
}
  .card-item {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    height: 180px;
    margin-bottom: 10px;

    &:hover {
      transition: 0.35s;
      border-color: $ub-hover-color;
      background-color: lighten($ub-hover-color, 35%);
    }

    .style-num-text {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;
    }

    .name-text {
      font-size: 12px;
    }
  }

}

.test-product-image-loader {

  .preview-image,
  &.preview-image {
    position: relative;
    margin: 0;
    padding: 10px;
    min-height: 100px;
    object-fit: contain;
  }

  .loading-spinner {
    // d-flex flex-column align-self-center align-items-center align-content-center justify-content-center
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    opacity: 0;

    &.show {
      transition-delay: 3s !important;
      transition: 0.3s;
      opacity: 1;
    }

    &.hide {
      transition-delay: 0s !important;;
      transition: 0.1s;
      opacity: 0;
    }
  }

  .img-holder {
    opacity: 1;
    &.loading {
      transition-delay: 0s !important;;
      transition: 0s;
      opacity: 0.2;
    }
    &.loaded {
      transition-delay: 0s !important;;
      transition: 0s;
      opacity: 1;
    }
  }

}





@media screen and (max-width: $bp-xl) {
  .test-page.test-products-page {
    .main-header {
      flex-direction: column;
    }
    .header-title {
      font-size: 24px;
    }
  }
}


@media screen and (max-width: $bp-mobile) {
  .test-page.test-products-page {
    .header-title {
      font-size: 24px;
    }
    .header-menu {
      flex-direction: column;
      align-items: center;

      .header-menu-item {
        margin: 2px 2px 0 2px;
      }
    }
  }
}
