.art-message {
    text-align: center; 
}

.add-art-container {
    display: flex; 
    flex-direction: column; 
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: $bp-desktop){
    .add-art-container {
        margin: 0; 
    }
}