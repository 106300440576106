.accordion .swatch__circle {
  margin: 0 0.5em 0 0;
}

.accordion__row {
  border-top: 1px solid $ub-grey--200;
  transition: color 0.2s ease-in;
  position: relative;
}

.accordion__label {
  transition: background-color 0.2s ease;
}

.accordion__label:hover {
  cursor: pointer;
  background-color: $ub-grey--100;
}

.accordion__label:hover .accordion__chevron {
  //left: 4px;
}

.accordion__chevron {
  position: relative;
  transition: -webkit-transform 300ms ease 0s, transform 300ms ease 0s;
}

.accordion__chevron--is-active {
  transform: rotateZ(90deg);
}

.accordion__option {
  transition: height 300ms ease 0s;
}


.beep {
  transition: height 300ms ease 0s;
}
