.text-location-summary-box {
  overflow: hidden;
}

.text-location-summary-title {
  align-self: center;
  margin-top: 20px;
  margin-bottom: 10px;  
  h6 {
    font-weight: bolder; 
  }
}

.text-location-summary-message {
  text-align: center; 
  margin-bottom: 20px; 
}

.text-location-detail {
  margin-bottom: 10px; 
  padding: 10px; 
  // border: 1px solid lightgray;
  cursor: pointer;
}

.text-location-detail-body {
  font-size: 28px;
  font-weight: bolder; 
  padding: 5px;
  margin: 5px 0;
  background-color: $locationbox-background; 
  color: white; 
  border: 1px solid $reallyLightGrey;
}

.text-location-summary-add {
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
}
.padding-right{
  padding-right: 30px;
  padding-left: 12px ;
}

.text-location-detail-cancel {
  align-self: center;
  margin-left: 6px;
  cursor: pointer;
  // transform: translateY(-5px);
  // svg {
  //   transform: rotate(45deg); 
  // }
}

.no-locations-open-warning {
  text-align: center; 
  background-color: #f4f4f4; 
  margin-top: 25px;
  margin-left: 25px; 
  margin-right: 25px;
  border-style: solid;
  border-color: #d2d2d2; 
  border-width: 1px;
  padding: 25px;
  border-radius: 5px;
  .warning-title{
    font-size: 20px; 
    padding: 5px; 
  }
  .warning-body{
    font-size: 16px; 
    padding-top: 5px; 
  }
}

.art-preview {
  margin: 0;
  max-width: 100px;
  max-height: 70px; 
  padding-top: 10%;
}

.add-new-text-btn {
  margin-top: 130px; 
  width: 134px; 
}

.type-detail {
  padding-left: 3px; 
}

.art-summary-text-red {
  color: #D40001;
}

.edit-icon{
  color: grey;
  
}