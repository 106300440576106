/*
Desktop defined by default 
then Tablet (max-width: $bp-desktop)
then mobile (max-width: $bp-tablet) */

#builder {
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  background-color: $ub-grey--100;
  position: relative;
  z-index: 0;
}

.grid-container {
  height: calc(100% - 110px);
  width: 100%;
}
.app-grid {
  height: 100%;
  width: 100%;
  display: grid;
  // grid-template-columns: 480px 1fr;
}
.sidebar-container {
  //height: 100%;
  width: 100%;
}
.preview-container {
  height: 100%;
  width: 100%;
  // display: grid;
  // grid-template-columns: 1fr 3fr;
}

.block-actions {
  opacity: 0.8;
  background-color: #ccc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

@media screen and (max-width: $bp-desktop) {
  .grid-container {
    overflow: visible !important;
    .app-grid {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 60px;
      .sidebar-container {
        grid-row: 2;
      }
      .preview-container {
        grid-row: 1;
        grid-template-columns: 2fr 1fr;
      }
    }
  }
}

@media screen and (max-width: $bp-mobile) {
  .grid-container {
    .app-grid {
      .preview-container {
        grid-template-rows: 2fr 20px;
        grid-template-columns: 1fr;
      }
    }
  }
}
