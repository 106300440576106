/*
Desktop defined by default 
then Tablet (max-width: $bp-desktop)
then mobile (max-width: $bp-tablet) */

html {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

html, body {
  color: $ub-font-color;
  line-height: 1.15;
  font-family: $ub-font-family;
  font-size: $ub-font-size;
  font-style: normal;
  letter-spacing: .025em;
}

html, body, #root, .App {
  height: 100%;
}

#iframe {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}

header {
  background-color: #03a9f4;
  height: 60px;
}

footer {
  background-color: #4b4b4b;
  height: 60px;
}

a {
  color: $ub-font-color;
  &:hover {
    color: $ub-hover-color;
  }
}

