/*
Desktop defined by default 
then Tablet (max-width: $bp-desktop)
then mobile (max-width: $bp-tablet) */

.missing-roster-container {
  width: auto;
  height: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;

  .top-container {
    display: flex;
    height: 400px;
    width: 95%;
    justify-content: space-between;
    align-items: center;

    .left-column {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-flow: column;

      .preview-image {
        width: 100%;
        height: 200px;
        background-color: $ub-preview-card-bg;
        border: 1px solid $ub-preview-card-border;
        border-radius: 1px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .missing-roster-download-template {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
      }
    }
  
    .right-column {
      width: 65%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-flow: column;

      .custom-roster-message-box {
        margin-top: 30px;
        background-color: #F4F4F4;
        height: 177px;
        border-radius: 5px;
        border: 1px solid #D2D2D2;
        display: flex;
        justify-content: space-around !important;
        align-items: center;
        flex-flow: column;
      }

      .size-quantity-container {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        .size-quantity-input {
          width: 23%;
          height: auto;
          margin-bottom: 5px;
        }
      }
    }

  }
  
  .bottom-row {
    width: 95%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-button {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .right-buttons {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .btn-atc {
        width: 50%;
        height: 100%;
      }
    }
  }
}

// tablet and mobile views
 @media (max-width: $bp-desktop) { 

  .missing-roster-modal {
    width: 100%;
    display: flex; 
    justify-content: center; 
    min-width: 270px; 
  }

  .missing-roster-container {
    width: 320px; 
    .top-container{
      flex-flow: column; 

      .left-column {
        width: 100% !important;
        flex-flow: row;
        padding-right: 20px;

        .preview-image {
          margin-right: 10px;
        }  

        .roster-file-interface {
          display: flex; 
          align-items: flex-end;
          height: 100%; 
        }
      }
      .right-column {
        width: 100% !important;
        margin-top: 20px; 

        .atc-header-message {
          display: none;
        }

        .custom-roster-message-box{
          display: none; 
        }
      }
    }

    .bottom-row {
        
      .left-button {
        width: 0px; 
      }
      .right-buttons{
        width: 100%;
        justify-content: space-between; 
        .btn-atc{
          width: 90px;
        }
      }
    }
  }
}
