.color-swatch-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -1px;   // make sure that negative margin matches ColorSwatch innerPadding value
  padding: 0;
  z-index: 1;

  .color-swatch-group-item {
    margin: 0;
    padding: 0;
  }
}

.chromagear-color-hint {
  width: 100%;
  height: 25px;
}