.help-bubble-container {
  display: flex;
  justify-content: center;
  z-index: 100;
  align-items: center;
  position: relative;
  right: 210px;
  top: -10px;
  opacity: 0;
  animation: helpBubble .5s 0s forwards;
  .help-bubble {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: black;
    border-radius: 3px;
    width: 175px;
    height: 100px;
    position: absolute;
    bottom: -65px;
    left: 100%;
  }
  .arrow-left {
    width: 0; 
    height: 0; 
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent; 
    border-left: 6px solid black; 
    position: absolute;
    left: 195px;
  }
}

@keyframes helpBubble {
  0% {opacity: 0;}
  100% {opacity: 1;}
}