.FileUploader {
  // margin-top: 25px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  width: 100%;

  .content-container {
    // border: 2px dashed rgb(221, 221, 221);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    // padding: 10px
    font-size: 16px;
    width: 100%;
  }

  .artUpload {
    font-size: 12px;
  }
}

.button {
  cursor: pointer;
  // margin-top: 20px;

  &disabled,
  &.disabled {
    cursor: inherit;
    pointer-events: none;
  }
}
