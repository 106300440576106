.delete-modal-container {
  width: 100%;
  height: 100px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;

   .delete-title {
    margin-bottom: 10px;
  }

   .delete-cancel-buttons-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

   .delete-end-message {
    font-size: 10px; 
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
  }

   .delete-modal-button-container {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
