.sidebar-bubble-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 50%;
  z-index: 1000 !important;
  top: -20px;
  opacity: 0;
  animation: helpBubble .5s 10s forwards;
  .help-bubble {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: black;
    border-radius: 3px;
    margin-top: 96px;
    width: 175px;
    height: 35px;
    position: absolute;
    width: 250px;
    height: 45px;
    padding: 15px;
    // left: 50px;
    font-size: 14px;
    text-transform: none;
    font-family: 'Forza';

    right: 62px;



  }
  .arrow-left {
    width: 0; 
    height: 0; 
    margin-top: 44px;
    left: -10px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent; 
    border-right: 6px solid black; 
    position: absolute;
    transform: rotate(90deg);
    // left: 44px;
  }
}

@keyframes helpBubble {
  0% {opacity: 0;}
  100% {opacity: 1;}
}