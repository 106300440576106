.AddToDesignButton {
    width: 100%;
    height: 35px;
    text-align: center;
    color: white;
    background-color: black;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .15s;
    cursor: pointer;
  
  &:hover {
    background-color: $darkGrey;
    transition: .15s;
  }
  
  &.disabled {
    width: 100%;
    height: 35px;
    text-align: center;
    color: #333333;
    background-color: #EBEBE4;
    border: 1px solid #D2D2D2;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    pointer-events: none;
    align-items: center;
    transition: .15s;
    cursor: pointer;
  }
}

.card-design{
  height: 300px !important;
  width: 150px !important;
  &:hover {
    cursor: pointer;
    transition: 0.35s;
    border-color: $ub-hover-color;
    background-color: lighten($ub-hover-color, 35%);
  }
}