.EditDrawer {
}

.add-art-container_navigate-button {
  @extend .button--light;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 15px;

  span {
    padding-left: 10px;
  }
}
