.preview-render-generator {

  $debug: false;

  position: absolute;
  top: -3000px;
  left: -3000px;
  z-index: -101;
  margin: 0;
  padding: 0;
  display: block;
  opacity: 0;
  pointer-events: none;

  @if $debug == true {
    // debug only
    top: 920px;
    left: 700px;
    opacity: 1;
    z-index: 101;
  }

  .preview-render-content,
  .preview-render-assets-content {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;

    @if $debug == true {
      display: flex;
      flex-direction: row;
      border: 10px solid white;
      background-color: lightblue;
      min-width: 900px;
      min-height: 900px;
    }
  }

  .preview-render-item,
  .preview-render-assets-item {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 600px;
    height: 600px;
    margin: 0;
    padding: 0;

    @if $debug == true {
      position: relative;
      top: auto;
      left: auto;
      // margin: 10px;
      // padding: 10px;
      // border: 2px solid blueviolet;
    }
  }
}
