.color-swatch-stitch-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  // margin: 0 -1px;   // make sure that negative margin matches ColorSwatch innerPadding value
  // padding: 0;
  z-index: 1;
}

.chromagear-color-hint {
  width: 100%;
  height: 25px;
}

.stitching-color-border {
  border-color: $ub-grey--300;
  border-style: solid;
  border-top-width:20px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-right-width: 2px;
}

.stitch-color-name {
  font-family: "slate-std-med";
  text-transform: uppercase;
  color: #2e2e2e;
}

.stitch-color-name-bold {
  font-family: "slate-std-med";
  color: $ub-black;
  text-transform: uppercase;
  font-weight: bold;
}

.selected-checkmark {
  position: absolute;
  // margin: 0 auto;
  text-align: center;
  z-index: 1;
}

.selected-color {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  font-family: $ub-font-forza-medium;
  text-transform: uppercase; //build-671
}