.checkbox-container {
  display: flex;
  position: relative;
  justify-content: center;
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default checkbox */
  input {
    position: relative;
    opacity: 0;
    display: none;
    cursor: pointer;
    /* On mouse-over, add a grey background color */
    &:hover ~ .checkmark {
      background-color: #ccc;
    }
  }

  .checkbox-label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .checkbox__label {
    font-size: 16px;
  }

  .checkbox__rightLabel {
    font-size: 14px;
  }
  /* Style the checkmark/indicator */
  .checkmark {
    display: inline-block;
    flex-shrink: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 2px solid $almostBlack;
    border-radius: 2px;
  }
  /* When the checkbox is checked, add a dark background */
  input:checked ~ .checkmark {
    background-color: $almostBlack;
  }
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark::after {
    content: '';
    position: absolute;
    display: none;
    left: 7px;
    top: 1px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  /* Show the checkmark when checked */
  input:checked ~ .checkmark::after {
    display: block;
  }
}

.checkbox__description {
  font-size: 0.8em;
  color: $ub-grey--700;
}