.btn-outline{
    border: 3px solid black;
  }
  .btn-outline:hover{
    color: white;
  }
  .btn-outline-red{
    border: 3px solid #D40001;
    color: #D40001;
  }
  .btn-outline-red:hover{
    background-color: #D40001 !important;
    border: 3px solid #D40001 !important;
    color: white;
  }