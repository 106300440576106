.slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;

  .slider__slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding-right: 10px;
    div {
      padding-bottom: 5px;
    }
  
    input {
      cursor: pointer;
      width: 100%;
    }
  }

  .slider__dropdown {

    .slider__dropdown__degreediv {
      position: relative;
      bottom: 30px;
      left: 22px;
      z-index: 1;
      height: 1px;
      width: 1px;
    }

    div {
      padding-left: 12px;
    }

    input {
      border: 1px solid #D2D2D2;
      width: 66px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 4px; 
      padding-right: 4px; 
      text-align: right; 
    }
    
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {  
       margin-left: 12px; 
       opacity: 1;
    }
  }
}