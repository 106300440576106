/* ==========================================================================
   Variables that will be used throughout stylesheets in the app.
   ========================================================================== */


/* ==========================================================================
   Font Face Styles
   ========================================================================== */

// NOTE: I used this tool to package and download the fonts
// https://google-webfonts-helper.herokuapp.com/fonts

// @import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i&display=swap');
@import url('../assets/fonts/ibm-plex-sans.css');
$ff-ibmPlex: 'IBM Plex Sans',
sans-serif;


/* ==========================================================================
   Sizing Options
   ========================================================================== */

// Breakpoints
$bp-xl: 1200px;
$bp-lg: 992px;
$bp-md: 768px;
$bp-sm: 576px;
$bp-xs: 568px;
$bp-desktop: $bp-lg;
$bp-mobile: $bp-sm;

// Widths & Heights
$ub-nav--width: 76px;

/* ==========================================================================
   Font Options
   ========================================================================== */

$ub-font-size: 14px;
$ub-font-weight: 400;
$ub-nav-font-size: 17px;
$ub-nav-font-weight: 500;
$ub-font-family: 'IBM Plex Sans',
'Helvetica Neue',
'Helvetica',
'Arial',
'sans-serif';
$ub-font-slate-light-italic:'Slate Std Light Italic';
$ub-font-slate-bold: 'Slate Std Bold';
$ub-font-forza-bold: "Forza-bold";
$ub-font-forza-medium: "Forza";
$ub-font-forza-light: "Forza-light";
$ub-font-slate-std:"Slate Std Regular";
$ub-font-slate-std-medium:"Slate-std-med";

/* ==========================================================================
   Color Options
   ========================================================================== */
$ub-font-color: #303030;
$ub-black: rgba(0, 0, 0, 1); // #000000
$ub-black--transparent: rgba(0, 0, 0, 0); // #000000
$ub-white: rgba(255, 255, 255, 1); // #ffffff
$ub-white--transparent: rgba(255, 255, 255, 0); // #ffffff
$ub-grey--100: #f3f3f3;
$ub-grey--200: #dddddd;
$ub-grey--300: #cfcfcf;
$ub-grey--400: #b4b4b4;
$ub-grey--500: #a9a9a9;
$ub-grey--600: #9d9d9d;
$ub-grey--700: #949494;
$ub-nav-fill: #505050;
$ub-nav-bgcl: #989898;
$ub-red: #d11113;
$ub-hover-color: #45abe3;
$ub-tab-underline: #6A6A6A;
$ub-add-to-cart-button-color:#ED2024;
$ub-color-swatch-border: #D6D6D6;
$ub-selected-text-color: #0B0B0B;
$ub-selected-border-color: #71b9f1;
$ub-selected-bg-color: #dbeaf5;
$ub-hover-selected-bg-color: darken(#dbeaf5, 5%);
$ub-selected-design: #707070;
$ub-footer-color:#252525;
$ub-button-shadow:#00000029;
$ub-art-gallery-card-background: #F5F5F5;
$ub-art-gallery-card-border: #E8E8E8;


// Colors
$founderRed: #EC2024;
$almostBlack: rgb(20, 20, 20);
$offWhite: rgb(243, 243, 243);
$mainWhite: #ffffff;
$darkGrey: #4B4B4B;
$heading-grey: #2E2E2E;
$textPlaceholder: #9e9e9e;
$textGrey: #707070;
$borderGray: #DCDCDC;
$backDropGrey: #F7F7F7;
$founderTextBlack: #0B0B0B;
$founderTextPlaceholder: #9e9e9e;
$reallyLightGrey: #cecece;
$borderGrey: #e4e4e4;
$founderDarkGrey: #707070;
$ruskinBlue: #06245C;
$ruskinGold: #FFC400;
$linkBlue: #007BFF;
$backgroundGrey: #e8e8e8;
$selectedBorderColor: #0277BD;
$artCardBackground: #e2f1fc;
$artCardBackgroundActive: #e2f1fc;
$locationbox-background: #F5F2F2;


// UI component variables

$ub-preview-card-bg: #bfbcbc;
$ub-preview-card-border: #707070;
$ub-pattern-bg: fade-out(#bfbcbc, 0.6);
$ub-pattern-bg-hover: fade-out(#bfbcbc, 0.2);
$ub-art-gallery-card-background: #F5F5F5;
$ub-art-gallery-card-border: #E8E8E8;

// Table

$ub-table-bg-light: #FFFFFF;
$ub-table-bg-dark: #F8F8F8;
$ub-table-border: transparent;