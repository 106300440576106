.upload-title-bar {
  display: flex; 
  width: 100%; 
  text-align: center; 
  background-color: $ub-nav-bgcl; 
  font-size: 14px;
  padding: 5px;
  margin-top: 5px;
}
.upload-title-bar__text {
  flex-grow: 1;
  font-size: 16px;
  padding-top: 5px;
}

.upload-title-bar__toggle-button {
  flex-shrink: 1;

  &.btn {
    &.btn-icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      margin: 0;
      padding: 4px;
    }
    &.active {
      background-color: $ub-nav-fill; 
    }
  }
}

.btn-edit {
  background-color: $ub-grey--200;
  border-color: $ub-white;
  &:hover {
    background-color: $ub-white;
    color: $ub-black;
    border-color: $ub-black;
  }
}