.ShareModal {
    display: grid;
    grid-template-columns: 200px auto;
    grid-template-rows: auto auto;
    .preview-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        .size {
            height: 250px;
            width: 198px;
            background-color: #bfbcbc;
            border: 1px solid #707070;
        }
        .preview-buttons {
            margin-top: 3px;
            z-index: 10;

            button {
                font-size: 11px;
                color: black;
            }
            .active-btn {
                background-color: #e2f1fc;
                border-color: #71b9f1;
                box-shadow: none;
            }
        }

        .preview-image {
            width: 100%;
            height: 100%;
            object-fit: contain;

            transition: 1500ms;
        }
    }
    .inputs {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 10px;
        .intro {
            text-align: center;
            .sub-title {
                font-size: 16px;
            }
            .message {
                font-size: 12px;
            }
        }
        .input {
            // margin-top: 20px;
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }
    .mockup-saved {
        font-size: 12px;
        color: green;
        margin-top: 5px;
        text-align: center;
    }

    h2 {
        font: 13px Helvetica;
        margin-top: 30px;
        text-align: center;
        text-transform: uppercase;
    }

    h2.background {
        position: relative;
        z-index: 1;

        &:before {
            border-top: 2px solid #dfdfdf;
            content: "";
            margin: 0 auto;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            bottom: 0;
            width: 25%;
            z-index: -1;
        }

        span {
            /* to hide the lines from behind the text, you have to set the background color the same as the container */
            background: #fff;
            padding: 0 15px;
        }
    }

    h2.double:before {
        /* this is just to undo the :before styling from above */
        border-top: none;
    }

    h2.double:after {
        border-bottom: 1px solid black;
        content: "";
        margin: 0 auto; /* this centers the line to the full width specified */
        position: absolute;
        top: 45%;
        left: 0;
        right: 0;
        width: 85%;
        z-index: -1;
    }
}
.share-image {
    background-color: lightgray !important;
}
h2 {
    font: 13px Helvetica;
    margin-top: 30px;
    text-align: center;
    text-transform: uppercase;
}

h2.background {
    position: relative;
    z-index: 1;

    &:before {
        border-top: 2px solid #dfdfdf;
        content: "";
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        width: 25%;
        z-index: -1;
    }

    span {
        /* to hide the lines from behind the text, you have to set the background color the same as the container */
        background: #fff;
        padding: 0 15px;
    }
}

h2.double:before {
    /* this is just to undo the :before styling from above */
    border-top: none;
}

h2.double:after {
    border-bottom: 1px solid black;
    content: "";
    margin: 0 auto; /* this centers the line to the full width specified */
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    width: 85%;
    z-index: -1;
}

.btn-black {
    background-color: black;
    color: white;
}
@media screen and (max-width: $bp-mobile) {
    .ShareModal {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
}
