.color-picker {
  // $border-color: #D2D2D2;
  min-width: 30%;

  .pattern-toggle-button {
    width: 33px !important;
    height: 33px !important;
  }

  .pattern-swatch-class {
    top: 17.5px !important;
  }

  .dropdown-toggle-button {
    position: relative;
    width: 28px;
    height: 28px;
    border: 1px solid $ub-color-swatch-border;
    background-color: transparent;
    border-radius: 4px;

    .dropdown-toggle-button-content {
      position: absolute;
      top: -4px;
      left: -4px;
      right: 2px;
      bottom: 2px;
      margin: 0;
      padding: 0;
      z-index: -1;

    }
  }

  .carent-container {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: 8px;
    height: 8px;
    border: 1px solid $border-color;
    background-color: $border-color;
    border-radius: 1px;
  }

  .caret {
    position: absolute;
    right: 0px;
    bottom: 1px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid #6D767E;
  }

  .dropdown-menu {
    width: 480px !important;
    height: 450px !important;
    //height: 100%;
    color: white;
    //border: #D6D6D6 1px;
    position: absolute !important;
    //margin-top: 70%;
    margin-left: 0px !important;
    //right: 300px !important;
    //left: 100px !important;
    //top: 100px !important;
    //overflow: hidden !important;
    max-height: 500px !important;
  }

  .text-color-hint {
    color: rgb(48, 48, 48);
    position: absolute;
    bottom: -25%;
    left: 0%;
    font-size: 12px;
  }

  .custom-caret-placeholder {
    width: 25px;
    height: 12px;
    pointer-events: none;
  }

  .custom-caret-container {
    width: 10px;
    height: 10px;
    padding: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d2d2d2;
    position: relative;
    bottom: -5px;
    right: -10px;
  }

  .custom-caret {
    width: 0px;
    height: 0px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #6D767E;
  }

  .color-picker-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-flow: column;
  }
}

.disabled-color-picker {
  pointer-events: none !important;
}

.chromagear-color-swatch-group {
  width: 480px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chromagear-color-swatch-group1 {
  //width: 198px;
  width: 480px !important;
  height: 400px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chromagear-color-swatch-group:hover {
  background-color: white;
}

.color-picker-hover:hover {
  background-color: white;
}

.chromagear-hover-color-tip {
  width: 100%;
  height: 30px;
  padding-left: 21px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


// desktops
@media (min-width: 1600px) {
  .dropdown-menu {
    width: 480px !important;
    height: 450px !important;
    //height: 100%;
    color: white;
    //border: #D6D6D6 1px;
    position: absolute !important;
    //margin-top: 70%;
    margin-left: 0px !important;
    //right: 300px !important;
    //left: 100px !important;
    //top: 100px !important;
    //overflow: hidden !important;
    max-height: 500px !important;
  }

  .chromagear-color-swatch-group1 {
    //width: 198px;
    width: 480px !important;
    height: 400px !important;
    overflow-y: scroll;
    overflow-x: hidden;
  }

}

@media screen and (max-height: 700px) {
  .dropdown-menu {
    max-height: 300px !important;
  }
}