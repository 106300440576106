.sidebar {
  // flex: 1 1 100%;
  // box-shadow: 0 0px 0px 0px rgba(0,0,0,.2), 0 0px 2px 0 rgba(0,0,0,.14), 0 0px 5px 0 rgba(0,0,0,.12);
  background-color: $ub-white;
  height: 100%;
  max-height: 690px;
  min-width: 300px;
  // max-width: 700px;
}

.sidebar__wrapper {
  display: flex;
  flex: 1 1 100%;
  height: inherit;
  max-height: 100%;
  overflow: hidden;
}

.sidebar__actions {
  position: absolute;
  z-index: 10000;
  height: 80px;
  width: 480px;
  bottom: 0;
  padding: 18px;
  background-color: $ub-white;
  border-top: 1px solid $ub-grey--100;
}

.sidebar__actions button {
  height: 46px;
}

.fade-in {
  height: 100%;
}

.add-location-drawer {
  height: 100%;
  flex-shrink: 0;
  overflow: visible;
}

.fancy-scrollbar {
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-button:start:decrement,
  &::-webkit-scrollbar-button:end:increment {
    background: transparent;
    display: none;
  }

  &::-webkit-scrollbar-track-piece {
    background: rgba(0, 0, 0, 0.0);
  }

  &::-webkit-scrollbar-track-piece:vertical:start {
    border-radius: 4px 4px 0 0;
  }

  &::-webkit-scrollbar-track-piece:vertical:end {
    border-radius: 0 0 4px 4px;
  }

  &::-webkit-scrollbar-track-piece:horizontal:start {
    border-radius: 4px 0 0 4px;
  }

  &::-webkit-scrollbar-track-piece:horizontal:end {
    border-radius: 0 4px 4px 0;
  }

  &::-webkit-scrollbar-thumb:vertical,
  &::-webkit-scrollbar-thumb:horizontal {
    background: rgba(0, 0, 0, 0.10);
    border-radius: 4px;
    display: block;
    height: 48px;
  }

  &:hover {

    &::-webkit-scrollbar-thumb:vertical,
    &::-webkit-scrollbar-thumb:horizontal {
      background: rgba(0, 0, 0, 0.25);
    }
  }
}



@media screen and (max-width: $bp-desktop) {

  .sidebar {
    width: 100%;
    max-width: 1000px;
    min-width: 200px;
  }

  .sidebar__actions {
    display: none;
  }


}

// IE 9 and above
// temp hack to prevent IE specific issue where sidebar shrinks too small too fast and is not easily used.
// this causes the sidebar to not shrink as well when going to smaller sizes.
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .sidebar {
    flex: 1 0 100%;
  }
}