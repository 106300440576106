.ArtGallery {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4px;
  overflow-y: scroll;
  padding: 5px 0px;
}

.ArtGalleryOptions {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 170px;
  justify-content: center;

  // overflow-y: scroll;
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 94px);
    grid-gap: 4px;
    justify-content: center;
    max-height: 190px;
    overflow-y: auto;
    padding: 5px 0px;
  }
}

.input-text:focus::placeholder {
  color: transparent;
}

.input-text {
  font-family: $ub-font-slate-std !important;
  font-size: 18px !important;
  text-align: center;
  border: 1px solid $ub-grey--600;
  border-radius: 3px !important;
  
}

.input-text::placeholder {
  color: $ub-grey--600 !important;
  opacity: 1; /* Firefox */
}

.input-text:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $ub-grey--600 !important;
}

.input-text::-ms-input-placeholder { /* Microsoft Edge */
  color: $ub-grey--600 !important;
}

