$ub-table-bg-light: #ffffff !default;
$ub-table-bg-dark: #F8F8F8 !default;
// $ub-table-border: #DDDDDD !default;
$ub-table-border: #E2E2E2 !default;

$ub-table-text-size: 12px !default;
$ub-table-text-color: #303030 !default;
$ub-table-text-weight: 400 !default;

$ub-table-header-text-size: 12px !default;
$ub-table-header-text-color: #303030 !default;
$ub-table-header-text-weight: 600 !default;

$ub-table-row-height: 42px !default;
 //Desktop
 @media (min-width: 1600px) {
  .roster-table {
    .tbl-table {
      // font-size: 12px;
      // font-weight: 400;

      .tbl-row-header {
        font-size: 16px;
        font-weight: 400;
        border: transparent !important;
        align-items: center;
      }
      
      .tbl-col-0 {
      text-align: center !important;
      vertical-align: middle !important;
      background-color: white !important;
      border: transparent !important;
      padding-top: 6px;
      outline: transparent !important;
      width: 5%;
      }
      .tbl-col-1 {
        width: 32%;
      }
      .tbl-col-2 {
        width: 18%;
      }
      .tbl-col-3 {
        // border: 1px solid #e2e2e2 !important;
        width: 12%;
      }
      .tbl-size-3{
        border: 1px solid #e2e2e2 !important;
      }
      .tbl-col-4 {
        width: 10%;
      }
      .tbl-col-5 {
        width: 9%;
      }
      .tbl-col-6 {
        text-align: center !important;
      vertical-align: middle !important;
      background-color: white !important;
      border: transparent !important;
      padding-top: 6px;
      padding-bottom: 3px;
      position:relative;
      outline: transparent !important;
      width: 7%;
        }
    }
    .trash-icon-padding{
      padding-bottom: 20px;
      padding-top: 10px;
    }
  }
  .tbl-complex {
    width: 100%;
    height: 25px;
    display: flex;
    // justify-content: right;
    align-items: center;
    position: relative;
    padding-top: 10px;

    // .tblc-arrow {
    //   position: absolute;
    //   left: 35px;      
    //   cursor: pointer;
    // }

    select {
      // padding: 7px;
      height: 18px;
      border-radius: 2px;
      // padding-bottom: 1px;
      // padding-top: 1px;
      // padding-left: 9px;
      box-shadow: none;
      background: transparent;
      background-image: none;
      // -webkit-appearance: none;
      // -moz-appearance: none;
      // appearance: none;
      margin: 1px;
      cursor: pointer;

      .select__option {
        background-color: white;
      }
      
    }

    div {
      cursor: pointer;
      // display: flex;
      // align-items: flex-end;
      //margin-right: 2px;
      margin-left: 10.25em;

      @media (min-width: 1500px){
        margin-left: 14em;
      }
      @media (min-width: 1000px) and (max-width: 1100px){
        margin-left: 8em;
      }
      @media (min-width: 1100px) and (max-width: 1250px){
        margin-left: 9.5em;
      }



      svg.plusSvg {
        transform: rotate(45deg);
        z-index: 1;
      }
      
    }
  }
 }
//Laptop
 @media screen and (min-width: 1025px) and (max-width: 1599px){
.roster-table {
  .tbl-table {
    // font-size: 12px;
    // font-weight: 400;

    .tbl-row-header {
      font-size: 16px;
      font-weight: 400;
      align-items: center;
    }
    .tbl-col-0 {
      text-align: center !important;
      vertical-align: middle !important;
      background-color: white !important;
      border: transparent !important;
      padding-top: 6px;
      outline: transparent !important;
      width: 5%;
  }
    .tbl-col-1 {
        width: 32%;
    }
    .tbl-col-2 {
        width: 18%;
    }
    .tbl-col-3 {
      width: 15%;
    }
    .tbl-size-3{
      border: 1px solid #e2e2e2 !important;
    }
    .tbl-col-4 {
      width: 15%;
    }
    .tbl-col-5 {
        width: 20%;
      }
    .tbl-col-6 {
      text-align: center !important;
      vertical-align: middle !important;
      background-color: white !important;
      border: transparent !important;
      padding-top: 6px;
      padding-bottom: 3px;
      position:relative;
      outline: transparent !important;
      width: 7%;
    }
    }
  }
  .trash-icon-padding{
    padding-bottom: 20px;
    padding-top: 10px;
  }
}


.tbl-table-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 0 0 0;
}

.tbl-table {
  position: relative;
  // box-sizing: border-box;
  display: flex;
  flex-direction: column;
  // margin: 4px 0;
  margin: 0;
  padding: 0;

  // border: 1px solid $ub-table-border;

  color: $ub-table-text-color;
  font-size: $ub-table-text-size;
  font-weight: $ub-table-text-weight;

  // .tbl-edit-actions {
  //   position: absolute;
  //   top: -20px;
  //   right: 2px;
  //   z-index: 1;

  //   .btn {
  //     &.btn-icon {
  //       display: flex;
  //       flex-direction: row;
  //       justify-content: center;
  //       align-content: center;
  //       margin: 0;
  //       padding: 4px;
  //     }
  //     .icon {
  //       position: relative;
  //       display: flex;
  //       flex-direction: row;

  //       &.expanded {
  //         .text {
  //           width: 40px;
  //         }
  //       }
  //     }
  //   }
  // }

  .tbl-row-header {
    display: flex;
    flex-direction: column;
    margin: 0px 5px 0px 5px;
    padding: 0;
    font-family: $ub-font-forza-medium !important;
    font-size: $ub-table-header-text-size;
    font-weight: $ub-table-header-text-weight;
    color: $ub-grey--500;
  }

  .tbl-row {
    position: relative;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: $ub-table-row-height;
  }

  .tbl-cell {
    position: relative;
    margin: 0px 3px 0px 3px;
    text-align: center;
    height: 40px;
    font-family: $ub-font-slate-std-medium;
    font-size: 16px;

    ::placeholder{
      color: #E2E2E2 !important;
    }
    // opacity: 1;

    &.tbl-light {
      background-color: $ub-table-bg-light;
    }

    &.tbl-dark {
      background-color: $ub-table-bg-dark;
      //border: 1px solid #e2e2e2 !important;
    }

    &.tbl-cell-data {
      //border: 1px solid #e2e2e2 !important;
      opacity: 1;
    }

    svg.preview{
      align-items: center;
      vertical-align: middle;
      padding-top: 10px;

    }

    .tbl-complex {
      width: 100%;
      height: 25px;
      display: flex;
      // justify-content: right;
      align-items: center;
      position: relative;
      padding-top: 10px;

      // .tblc-arrow {
      //   position: absolute;
      //   left: 35px;      
      //   cursor: pointer;
      // }

      select {
        padding: 7px;
        height: 18px;
        border-radius: 2px;
        padding-bottom: 1px;
        padding-top: 1px;
        padding-left: 9px;
        box-shadow: none;
        background: transparent;
        background-image: none;
        // -webkit-appearance: none;
        // -moz-appearance: none;
        // appearance: none;
        margin: 1px;
        cursor: pointer;

        .select__option {
          background-color: white;
        }
        
      }

      .deleterow {
        cursor: pointer;
        // display: flex;
        // align-items: flex-end;
        //margin-right: 2px;
        margin-left: 10.25em;

        @media (min-width: 1500px){
          margin-left: 14em;
        }
        @media (min-width: 1000px) and (max-width: 1100px){
          margin-left: 8em;
        }
        @media (min-width: 1100px) and (max-width: 1250px){
          margin-left: 9.5em;
        }



        svg.plusSvg {
          transform: rotate(45deg);
          z-index: 1;
        }
        
      }

      input::-webkit-calendar-picker-indicator {
        opacity: 100;
        cursor: pointer;
      }
    }

    .tbl-complex1 {
      width: 100%;
      height: 25px;
      display: flex;
      justify-content: right;
      align-items: center;
      position: relative;

      .tblc-arrow {
        position: relative;
        left: 68px;
        transform: rotate(-90deg);
        pointer-events: none;
      }

      select {
        //padding: 2px;
        min-width: 70px;
        height: 18px;
        background: white !important;
        border: 1.25px solid #b2b2b2;
        border-radius: 2px;
        padding-bottom: 1px;
        padding-top: 1px;
        padding-left: 5px;
        box-shadow: none;
        background: transparent;
        background-image: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        //margin: 1px;
        cursor: pointer;

        .select__option {
          background-color: white;
        }
      }

      div {
        cursor: pointer;
        // display: flex;
        // align-items: flex-end;
        //margin-right: 2px;
        margin-left: 20px;

        svg {
          transform: rotate(45deg);
          z-index: 1;
        }
      }

      input::-webkit-calendar-picker-indicator {
        opacity: 100;
        cursor: pointer;
      }
    }

    input {
      width: 100%;
      height: $ub-table-row-height;
      margin: 0;
      padding: 0 5px;
      text-align: inherit;
      border: 1PX solid #E2E2E2;
      background-color: #f8f8f8;
      opacity: 1;
    }
  }

  .tbl-cell-price {
    position: relative;
    margin: 0px 3px 0px 3px;
    text-align: center;
    height: 40px;
    font-family: $ub-font-slate-std-medium;
    background-color: $ub-table-bg-light;
    width: 45px;
    border: 0;
  }

  .price-input{
    width: 100%;
      height: $ub-table-row-height;
      margin: 0;
      padding: 0 5px;
      text-align: inherit;
      border: none;
  }
  // .tbl-cell-preview {
  //   position: relative;
  //   margin: 0px 0px 0px 0px;
  //   text-align: center;
  //   height: 40px;
  //   font-family: $ub-font-slate-std-medium;
  //   background-color: $ub-table-bg-light;
  //   width: 4px;
  //   border: 0;
  // }
}

.tbl-trash {
  width: 100%;
  margin-top: 4px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  div {
    border: 1px solid #dddddd;
    padding: 2px;
    cursor: pointer;
    width: 103px;

    svg {
      margin-bottom: 1px;
    }

    span {
      padding-right: 2px;
    }
  }
}

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.price {
  font-family: $ub-font-slate-std-medium;
  font-size: 16px;
}

.tbl-cell-view{
  padding-top: 10px
}

.button-row{
  justify-content:center;
}

.roster-add-button{
  padding-left: 100px;
}