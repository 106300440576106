.color-swatch {
  position: relative;
  margin: 0;
  padding: 0;

  &.color-none {
    background-color: whitesmoke;
  }

  .color-none-icon {
    position: absolute;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    z-index: -1;
  }

  .selected-checkmark {
    position: absolute;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    z-index: 2;
  }
}

.slash-svg {
  // transform: translateY(-5px);
  padding-bottom: 3px;
  padding-right: 3px;
  padding-top: 2px;
  transform: rotate(45deg);

}

.btn-color-swatch {
  &.light-color {
    border: 0 !important;
  }
}

.slash-svg {
  // transform: translateY(-5px);
  padding-bottom: 3px;
  padding-right: 3px;
  padding-top: 2px;
  transform: rotate(45deg);

}