.artHeader {
    text-align: center;
    font-size: 18px;
    margin: 3px;
    font-family: $ub-font-slate-std;
}

.active-art-header {
    color: $ub-black;
    font-weight: bolder;
}

.inactive-art-header {
    color: $ub-grey--700;
}

.class-pointer {
    cursor: pointer;
}