/* ==========================================================================
  Small / Mobile / Default
   ========================================================================== */

.fabric {
  font-size: 0.9em;
}
.fabric__img {
  width: 100%;
  min-width: 75px;
  height: 100%;
  max-height: 30px;
  border-radius: 7px;

}
.fabric-image{
  width: 100%;
  min-width: 140px;
  height: 100%;
  max-height: 35px;
  border-radius: 7px;
  border: 0.25px solid $ub-grey--700;
}

input[type='radio'] {
  top: 9%;
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  outline: none;
  border: 2px solid $ub-selected-design;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: $ub-red;
  
}

input[type="radio"]:checked {
border-color: $ub-selected-design;
}
.fabric__detail-list {
  padding-left: 15px;
  margin-left: 0;
}

.fabric-detail {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  height: 85px; 
  width: 285px;
  border: 1px $borderGray solid;
  margin: 5px;   
  &:hover {
    background-color: $backDropGrey; 
  } 
}

.fabric-detail.selected {
  border: 3px $ub-selected-bg-color solid;
  border-color: $ub-selected-border-color; 
  &:hover {
    background-color: $ub-hover-selected-bg-color; 
  }   

}

.fabric-detail__img {
  position: relative;
  z-index: 1;
  height: 85px;
  width: 85px; 
  padding: 2px;
  img {
    width: 90%;
    height: 90%;
  }
  .fabric-detail__rec {
    position: absolute;
    top: 1px; 
    left: 5px;
    z-index: 3;
  }
}

.fabric-detail__description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 10px;
  width: 200px;
  padding: 5px;
  font-family: $ub-font-forza-medium;
  color: $ub-nav-fill;
}

.fabric-detail__top {
  display: flex;
  justify-content: space-between;
}

.fabric-detail__info {
  width: 70%;
}

.fabric-detail__title {
  font-size: 14px; 
  font-family: $ub-font-forza-medium;
  color: $ub-nav-fill;
}

.fabric-detail__blend {
  flex-wrap: wrap;
}

.fabric-detail__lower {
  display: flex; 
  justify-content: space-between;
}

.fabric-detail__ai {
  display: flex; 
  flex-direction: column; 
  justify-content: flex-end; 
}

.fabric-detail__details {
  width: 90%
}