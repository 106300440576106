.ViewActive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  .borders-btn {
    justify-self: flex-start;
    align-self: flex-start;
    margin: 2px;
  }
  span {
    padding-bottom: 10px;
  }
  .preview-canvas-two {
    position: absolute;
    opacity: 1;
    transition: .2s opacity ease-in;
    &.active {
      opacity: 1;
      animation-name: moveIn;
      animation-duration: 1s;
      animation: forwards;
    }
    &.inactive {
      opacity: 0;
      transition-delay: .2s;
      animation: forwards;
      animation-name: moveOut;
      animation-duration: .001s;
      animation-delay: .4s;
    }
  }
}

@keyframes moveIn {
  0%   {left: -100vw;}
  99%  {left: -100vw;}
  100% {left: 35%;}
}
@keyframes moveOut {
  0%   {left: 35%;}
  99%  {left: 35%;}
  100% {left: -100vw;}
}	
