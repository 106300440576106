.CardToggleButtonGroup {

  // display: grid;
  // grid-template-columns: repeat( 3, 1fr );
  // text-align: center;
  .warning {
    font-size: 10px;
    color: $founderRed;
  }

  .align-bottom {
    font-size: 15px;
    font-family: $ub-font-slate-std;
  }

  .card-button {
    font-size: 18px;
    font-family: $ub-font-forza-bold;
    box-shadow: 0px 3px 6px $ub-button-shadow;
    // background-color: $ub-grey--100;
    // border: 1px solid $ub-black;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
      transition: .15s;
      background-color: $ub-grey--200;

      &.warning {
        background-color: rgb(197, 168, 168);
      }
    }

    &.warning {
      background-color: rgb(214, 183, 183);
    }

    &.active {
      // transition: .15s;
      // border: 1px solid $ub-black;
      // background-color: $ub-grey--100;

      &.warning {
        background-color: rgb(197, 168, 168);
      }

      &:hover {
        cursor: pointer;
        box-shadow: 3px 5px 6px $ub-button-shadow;

        &.warning {
          background-color: rgb(189, 165, 165);
        }
      }
    }
  }

  .card-button-active {
    font-size: 18px;
    font-family: $ub-font-forza-bold;
    border: 1px solid $ub-black;
    box-shadow: 0px 3px 6px $ub-button-shadow;
    cursor: pointer;
    // background-color: $ub-grey--100;
    border-radius: 4px;
  }

  .card-button-disabled {
    cursor: pointer;
    font-size: 18px;
    font-family: $ub-font-forza-bold;
    color: $ub-grey--700;
    box-shadow: 0px 3px 6px $ub-button-shadow;
    border: 1px solid $ub-grey--700;
    background-color: $ub-grey--100;
    border-radius: 4px;
  }
}