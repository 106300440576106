.title {
  display: flex;
  flex-direction: column;
  padding: 5px 5px 0 5px;

  .title__brand {
    display: flex;
    flex-direction: row;
    font-size: 10px;

    .title__brand__logo {
      height: 16px;
      margin-right: 5px;
    }

    .title__brand__brand--name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 6px;
    }
  }

  .title__product {
    font-size: 16px;
  }
}

@media screen and (min-width: $bp-desktop) {
  .title {
    .title__product {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-center;
      font-size: 24px;
    }
  }
}
