.loading-page {
  width: 100%;
  height: 100%;
  margin: auto 0;
  padding: 50px;
  text-align: center;

  .loading-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    max-width: 600px;
  }

  .loading-spinner {
  }

  .loading-message {
    font-size: 48px;
    color: black;
    text-align: center;
  }
}
