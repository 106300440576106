.info-modal-container {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  strong{
    &.colored {
      color: $linkBlue;
    }
  }

  .title {
    margin-bottom: 2px;
    text-align: center;
    display:flex;
    width: 100%;
    font-size: 14px;
  }
  .title-note {
    text-align: left;
    padding: 0px 10px;
    font-size: 10px;
  }
  hr {
    background-color: $ub-grey--500;
    padding: 0px;
    margin-top: 4px;
    margin-bottom: 10px;
    width: 100%;
    height: 1px;
  }
  .info-1 {
    text-align: left;
    margin-bottom: 15px;
    padding: 0px 10px;
  }
  .info-2 {
    margin-bottom: 15px;
    padding: 0px 10px;
  }
  .subtext {
    font-size: 10px; 
    text-align: center;
    margin-bottom: 10px;
  }
.btn-outline{
  border: 3px solid black;
}
}
