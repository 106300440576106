
.new-color-picker {
  $border-color: #D2D2D2;
  min-width: 30%;

  .pattern-toggle-button {
    width: 33px !important;
    height: 33px !important;
  }

  .pattern-swatch-class {
    top: 17.5px !important;
  }

  .dropdown-toggle-button {
    //position: relative;
    //width: 208px;
    //height: 40px;
    border: 0px !important;
    padding: 0px !important;
    margin: 0px 5px 0px 0px;
    //border: 3px solid $border-color;
    background-color: transparent;
    //border-radius: 0;

    .dropdown-toggle-button-content {
      /*position: absolute;
      top: -3px;
      left: -3px;
      right: 3px;
      bottom: 3px;
      margin: 0;
      padding: 0;
      z-index: -1;*/
    }
  }

  .carent-container {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: 11px;
    height: 10px;
    border: 3px solid $border-color;
    background-color: $border-color;
  }

  .caret {
    position: absolute;
    right: -4px;
    bottom: -2px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #6D767E;
  }

  .dropdown-menu {
    width: 480px;
    height: 674px;
    //height: 100%;
    color: white;
    //border: #D6D6D6 1px;
    position: absolute !important;
    //margin-top: 70%;
    margin-left: 0px;
    //right: 300px !important;
    //left: 100px !important;
    //top: 100px !important;
    //overflow: hidden !important;
    max-height: 500px !important;
  }

  @media (min-width: 1040px) and (max-width: 1399px) {
    .dropdown-menu {
      max-height: 250px !important;
    }
  }

  @media (min-width: 1400px) and (max-width: 1599px) {
    .dropdown-menu {
      max-height: 300px !important;
    }
  }

  @media (min-width: 1600px) {
    .dropdown-menu {
      max-height: 350px !important;
    }
  }
  /*@media screen and (max-height: 700px) {
    .dropdown-menu {
      max-height: 300px !important;
    }
  }*/

  .text-color-hint {
    color: rgb(48, 48, 48);
    position: absolute;
    bottom: -25%;
    left: 0%;
    font-size: 12px;
  }


  .custom-caret-placeholder {
    width: 25px;
    height: 12px;
    pointer-events: none;
  }

  .custom-caret-container {
    width: 10px;
    height: 10px;
    padding: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d2d2d2;
    position: relative;
    bottom: -5px;
    right: -10px;
  }

  .custom-caret {
    width: 0px;
    height: 0px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #6D767E;
  }

  .color-picker-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-flow: column;
  }
}

.disabled-color-picker {
  pointer-events: none !important;
}

// .chromagear-color-swatch-group {
//   //width: 198px;
//   //height: 230px;
//   overflow-y: scroll;
//   overflow-x: hidden;
// }

.chromagear-color-swatch-group1 {
  width: 480px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  /*will-change: transform;
  overflow: auto;
  max-height: 300px;
  top: 0px;
  left: 0px;*/
  //transform: translate3d(0px, -130px, 0px);
}

// .chromagear-color-swatch-group:hover {
//   background-color: white;
// }

.color-picker-hover:hover {
  background-color: white;
}

.chromagear-hover-color-tip {
  width: 100%;
  height: 30px;
  padding-left: 21px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.layerName-style {
  font-size: 12px;
  color: #868686;
  opacity: 1;
  font-family: "Slate-std-med";
  text-align: center;
}

.colorName-style {
  font-size: 12px;
  color: #2E2E2E;
  opacity: 1;
  font-family: "Forza";
  text-align: center;
}