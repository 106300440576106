.color-swatch-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -1px;   // make sure that negative margin matches ColorSwatch innerPadding value
  padding: 0;
  z-index: 1;

  .color-swatch-group-item {
    margin: 0;
    padding: 0;

    &.selected {
      //border: 1px solid #000000;
      border-radius: 5px 5px 0px 0px;
      opacity: 1;
      background: #000000 0% 0% no-repeat padding-box;
    }
  }
}

.chromagear-color-hint {
  width: 100%;
  height: 25px;
}