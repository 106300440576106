ul.ul-list {
    padding-left: 20px;
    text-align: left;
    list-style-position: outside;
    // list-style-position: inside;
    font-family: $ub-font-slate-std;
    font-size: 14px;
     
}
.style-card {
    border: 1px solid $reallyLightGrey;
}
