.c-nav {
  display: flex;
  flex: auto;
  justify-content: flex-start;
  overflow: visible !important;
  // z-index: 500 !important;
}


.c-nav__container {
  height: 100%;
  overflow: visible !important;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.c-nav__btn {
  display: flex;
  position: relative;
  height: 54px;
  flex: auto;
  flex-direction: column;
  background: none;
  border: none;
  border-bottom: 4px solid #fff;
  justify-content: center;
  align-items: center;
  font-size: $ub-nav-font-size;
  font-family: $ub-font-forza-medium;
  font-weight: $ub-nav-font-weight;
  padding: 15px;
  transition: background-color 0.2s ease, color 0.2s ease;
  color: $ub-grey--700;

  svg {
    fill: #a9a9a9;
    margin-bottom: .74em;
  }

  &:after {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    opacity: 0;
    bottom: -3px;
    left: 0;
    right: 0;
    background: $ub-tab-underline;
    height: 7px;
    border-radius: 10px 10px 10px 10px;
    transition: -webkit-transform 0.2s, background-color 0.3s, opacity 0.2s;
    transition-timing-function: cubic-bezier(.665, .14, .785, .285);
    transform: scale(1, 0);
    transform-origin: center center;
    -webkit-transition: -webkit-transform 0.2s, background-color 0.3s, opacity 0.3s;
    -webkit-transition-timing-function: cubic-bezier(.665, .14, .785, .285);
    -webkit-transform: scale(1, 0);
    -webkit-transform-origin: center center;
  }

  &:last-child {
    border-right: none;
  }

  &:focus {
    outline: none;
  }
}

.c-nav__btn--is-active {
  color: $ub-black;
  background-color: $ub-white;

  &:after {
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.c-nav__svg {
  width: 24px;
  height: 24px;
}

.c-nav__btn:hover {
  cursor: pointer;
  background: $ub-white;
  color: $ub-black;
}

.c-nav__btn:hover .c-nav__svg {
  fill: $ub-black;
}

@media screen and (max-width: $bp-desktop) {
  .c-nav {
    display: flex;
    flex: auto;
    // justify-content: center;
    background-color: $ub-nav-bgcl;
    align-content: center;
    width: 100%;
  }

  .c-nav__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $ub-nav-bgcl;
    max-width: 70%;
    overflow: visible;
    overflow-x: auto;
  }

  .c-nav__btn {
    padding-top: 18px;
    border: none;
    color: $ub-nav-fill;
    background-color: $ub-nav-bgcl;

    svg {
      fill: $ub-nav-fill;
    }

    &:after {
      position: absolute;
      content: "";
      display: block;
      width: 100%;
      opacity: 0;
      bottom: -3px;
      left: 0;
      right: 0;
      background: $ub-tab-underline;
      height: 5px;
      border-radius: 10px 10px 10px 10px;
      transition: -webkit-transform 0.2s, background-color 0.3s, opacity 0.2s;
      transition-timing-function: cubic-bezier(.665, .14, .785, .285);
      transform: scale(1, 0);
      transform-origin: center center;
      -webkit-transition: -webkit-transform 0.2s, background-color 0.3s, opacity 0.3s;
      -webkit-transition-timing-function: cubic-bezier(.665, .14, .785, .285);
      -webkit-transform: scale(1, 0);
      -webkit-transform-origin: center center;
    }
  }

  .c-nav__btn--is-active {
    color: $ub-nav-fill;

    svg {
      fill: $ub-nav-fill;
    }
  }

}

@media screen and (max-width: $bp-mobile) {

  .c-nav__btn {
    height: 100%;
    padding-bottom: 15px;
    flex: 0 0 50px;
  }

  .c-nav__container {
    max-width: 100%;
    width: 100%;
  }
}