
.edit-userart {
  padding: 5px;
  max-width: 475px;
  align-self: center;
}

.userart__name {
  margin-bottom: 20px;
}

.userart__name-label{
  font-size: 12px;
}

.userart__img {
  display: flex;
  justify-content: center;
  background-color: $ub-preview-card-bg;
  border: 1px solid $ub-preview-card-border;
  border-radius: 1px;
  width: 220px;
  height: 180px;
  img {
  //   max-width: 220px;
  // max-height: 180px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.edit-userart__panels {
  display: flex; 
}

.edit-userart__lpanel{
  width: 250px;
  margin-right: 20px;
}

.userart__not-sure-button {
  height: 30px;
  font-size: 10px;
  margin-top: 5px;
  border: 1px solid #e4e4e4;
  &.btn-selected {
    border: 1px solid $ub-selected-border-color; 
    background-color: $ub-selected-bg-color;
  }
}

.edit-userart__numdc-label {
  font-size: 12px;
  width: 80%; 
}

.edit-userart__numdc-inputs{
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex; 
}

.user__numdc-input{
  text-align: center;
  width: 70px;
  height: 40px;
  &.not-sure-active {
    opacity: 0.5;
  }
}

.userart__numdc-use {
  font-size: 12px;
}

.edit-userart__approval-message {
  padding-top: 10px; 
  font-size: 11px;
}

.user__numdc-num-div {
  margin-top: 5px;
  margin-right: 10px;
  border: solid $selectedBorderColor 1px;
  &.not-sure-active {
    border: solid $borderGray 1px;
  }
}

.edit-userart__btnGroup {
  display: flex;
  justify-content: space-between;
  width: 32%;
  padding: 20px;
  position: absolute; 
  bottom: 0;
  right: 0;
}

.btn-not-ready {
  border-color: $ub-grey--100;
  color: $ub-grey--700;
}
.edit-userart__numdc{
  padding-bottom: 15px;
}