/* ==========================================================================
   popIn - scales element in from 0% to 1.25% back to 100%;
   ========================================================================== */

@-webkit-keyframes popIn {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  33% {
    -webkit-transform: scale(1.25);
    -moz-transform: scale(1.25);
    -ms-transform: scale(1.25);
    -o-transform: scale(1.25);
    transform: scale(1.250);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes popIn {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  33% {
    -webkit-transform: scale(1.25);
    -moz-transform: scale(1.25);
    -ms-transform: scale(1.25);
    -o-transform: scale(1.25);
    transform: scale(1.25);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes popIn {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  33% {
    -webkit-transform: scale(1.25);
    -moz-transform: scale(1.25);
    -ms-transform: scale(1.25);
    -o-transform: scale(1.25);
    transform: scale(1.25);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes popIn {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  33% {
    -webkit-transform: scale(1.25);
    -moz-transform: scale(1.25);
    -ms-transform: scale(1.25);
    -o-transform: scale(1.25);
    transform: scale(1.25);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

/* ==========================================================================
  Default
   ========================================================================== */
#drawer {
  height: 95%; 
}
.add-text {
  width: 92%;
  font-size: 0.86em;
}

.add-text__input {
  border: 2px solid $ub-grey--200;
  color: $ub-grey--700;
  font-size: 1.25em;
  display: block;
  transition: border-color 0.2s ease-in, color 0.2s ease-in;
}

.add-text__input:focus {
  outline: none;
  color: $ub-font-color;
  border-color: $ub-black;
}

.option {
  border-top: 1px solid $ub-grey--200;
  transition: color 0.2s ease-in;
  position: relative;
}

.option:last-child {
  border-bottom: 1px solid $ub-grey--200;
}

.option:hover {
  cursor: pointer;
  color: $ub-black;
}

.option:hover .option__chevron {
  left: 4px;
}

.option__chevron {
  //position: relative;
  left: 0;
  transition: left 0.2s ease-in-out;
  line-height: 0;
}

.option__panel {
  visibility: hidden;
  overflow: hidden;
  height: 0;
  transition: height 300ms ease 0s;
}