.lead-time-text {
    color: $ub-grey--300;
    font-size: 10px;
    font-family: $ub-font-slate-std;
    text-align: center;
    padding-top: 6px;
    padding-right: 4px;
  }

.lead-time-text-1 {
  color: $ub-grey--300;
  font-size: 10px;
  font-family: $ub-font-slate-std;
  text-align: center;
  padding-top: 11px;
  padding-right: 4px;
}

.lead-time{
  color:$ub-white;
  font-size: 24px;
  font-family: $ub-font-forza-bold;
  text-align: center;
  // padding-top:8px;
  padding-left: 8px;
  padding-right: 5px;
}

.lead-time-tbd{
  color:$ub-white;
  font-size: 18px;
  font-family: $ub-font-forza-bold;
  text-align: center;
  padding-top: 7px;
  padding-left: 8px;
}

.lead-time-subtext{
  color:$ub-white;
  font-size: 9px;
  font-family: $ub-font-forza-bold;
  //text-align: center;
  padding-top: 1px;
}

.lead-time-head{
  margin-left: 6%;
  margin-top: 3px;
}