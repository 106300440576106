.new-color-swatch {
  position: relative;
  margin: 0;
  padding: 0;

  &.color-none {
    background-color: whitesmoke;
  }

  .color-none-icon {
    position: absolute;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    z-index: -1;
  }

  .newcolor-swatch-icon {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .selected-checkmark {
    position: absolute;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    z-index: 2;
    width: 32px;
    height: 32px;
    border-radius: 15px;
    background: white;
  }
}

.btn-color-swatch1 {
  @extend .btn;
  margin: 0;
  padding: 1px;
  width: 60px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  //border: 1px solid transparent;
  border: 0px;
  z-index: 10;
  box-sizing: border-box;
  opacity: 1;

  .color-swatch-icon {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  /*&.light-color {
    border: 1px solid #DCDCDC;
  }*/

  &.multi-color {
    width: 60px;
    border: 1px solid #DCDCDC;
    display: flex;
    flex-direction: row;


    &.multi-color-3 {
      width: 60px;
    }
    &.multi-color-4 {
      width: 60px;
    }
  }

  &.selected {
    // border: 1px solid #0277BD;    // TODO: use variable.scss $selectedBorderColor
    // box-shadow: 0 0 1px 1px #0277BD;
    // z-index: 2;
    color: white;
    margin: 0px !important;
    padding: 0px !important;
  }
}

.btn-color-swatch2 {
  @extend .btn;
  //margin: 0px 5px 0px 0px;
  padding: 1px;
  width: 208px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  //border-radius: 5px;
  //border: 1px solid transparent;
  border: 0px;
  z-index: 10;
  box-sizing: border-box;
  opacity: 1;

  .color-swatch-icon {
    position: relative;
    //margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  /*&.light-color {
    border: 1px solid #DCDCDC;
  }*/

  &.multi-color {
    width: 50px;
    border: 1px solid #DCDCDC;
    display: flex;
    flex-direction: row;


    &.multi-color-3 {
      width: 62px;
    }
    &.multi-color-4 {
      width: 62px;
    }
  }

  &.selected {
    // border: 1px solid #0277BD;    // TODO: use variable.scss $selectedBorderColor
    // box-shadow: 0 0 1px 1px #0277BD;
    // z-index: 2;
    color: white;
    margin: 0px !important;
    padding: 0px !important;
  }
}

@media (min-width: 1040px) and (max-width: 1399px) {
  .btn-color-swatch2 {
    width: 208px;
    height: 40px;
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .btn-color-swatch2 {
    width: 233px;
    height: 40px;
  }
}

@media (min-width: 1600px) {
  .btn-color-swatch2 {
    width: 258px;
    height: 50px;
  }
}

.btn-color-swatch3 {
  @extend .btn;
  //margin: 0px 5px 0px 0px;
  padding: 1px;
  width: 114px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  //border-radius: 5px;
  //border: 1px solid transparent;
  border: 0px;
  z-index: 10;
  box-sizing: border-box;
  opacity: 1;

  .color-swatch-icon {
    position: relative;
    //margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  /*&.light-color {
    border: 1px solid #DCDCDC;
  }*/

  &.multi-color {
    width: 50px;
    border: 1px solid #DCDCDC;
    display: flex;
    flex-direction: row;


    &.multi-color-3 {
      width: 62px;
    }
    &.multi-color-4 {
      width: 62px;
    }
  }

  &.selected {
    // border: 1px solid #0277BD;    // TODO: use variable.scss $selectedBorderColor
    // box-shadow: 0 0 1px 1px #0277BD;
    // z-index: 2;
    color: white;
    margin: 0px !important;
    padding: 0px !important;
  }
}

@media (min-width: 1040px) and (max-width: 1399px) {
  .btn-color-swatch3 {
    width: 126px;
    height: 40px;
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .btn-color-swatch3 {
    width: 139px;
    height: 40px;
  }
}

@media (min-width: 1600px) {
  .btn-color-swatch3 {
    width: 155px;
    height: 50px;
  }
}
