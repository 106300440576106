
.ViewSelectorContainer {
  height: 100%;
  .ViewSelector {
    background-color: #ffffff;
    display: grid;
    height: 100%;
    width: 120px;
  }
}

@media screen and (max-width: $bp-mobile) {
  .ViewSelectorContainer {
    width: 100vw;    
    .ViewSelector {
      width: 100%;
      display: flex;
      justify-content: space-around;
     }
  } 
}
