.asset-library {
  width: 100%; 
}

.asset-library__search {
  display: flex;
  position: relative; 
    input {
      height: 35px;
      width: 100%; 
      text-align: center; 
    }

    ::-webkit-input-placeholder {
      text-align: center;
      color: $textGrey;
   }
   
    :-moz-placeholder { /* Firefox 18- */
      text-align: center;        
      color: $textGrey;
   }
   
    ::-moz-placeholder {  /* Firefox 19+ */
      text-align: center; 
      color: $textGrey; 
   }
   
    :-ms-input-placeholder {  
      text-align: center;
      color: $textGrey; 
   }
   .asset-library__search_append {
     position: absolute;
     right: 10px;
     margin-top: 5px;
   }
}

.asset-library_fast_links {
  display: flex;
  justify-content: space-between; 
  .fast_link {
    border: solid 1px black;  
    padding: 5px; 
    margin-top: 5px; 
    background-color: $ub-white;  
      &:hover{
      border-color: $ub-hover-color;
      background-color: $backgroundGrey;
      }
  }
  .browse_all {
    padding: 2px; 
    border: solid 1px black; 
    text-align: center; 
    justify-content: center; 
    margin-top: 5px; 
    font-size: 14px;
    width: 85px; 
    background-color: $ub-white; 
      &:hover{
       border-color: $ub-hover-color;
       background-color: $backgroundGrey;
      }
  }
  .fast_link.fast_link_selected {
    border:solid 2px $ub-selected-border-color;
    padding: 4px; 
    background-color: $ub-selected-bg-color;
      &:hover{
      background-color: $ub-hover-selected-bg-color;
      }
  } 
  
  .browse_all.fast_link_selected {
    padding: 1px; 
    border-color: $ub-selected-border-color;
    background-color: $ub-selected-bg-color;  
      &:hover{
      background-color: $ub-hover-selected-bg-color;
      }
  }
}

.asset-library__nav_title {
  width: 100%; 
  text-align: center; 
  background-color: $ub-nav-bgcl; 
  font-size: 14px;
  padding: 5px;
  margin-top: 5px;
}

.asset-library__results {
  width: 100%; 
  height: 350px; 
  border: solid 1px $borderGray; 
  .asset-results{
    display: flex; 
    align-content: flex-start;
    justify-content: center;
    flex-wrap: wrap; 
      .asset-item {
        display: block; 
        text-align: center;
        border: solid 1px $ub-preview-card-border; 
        margin: 2px;
        padding: 2px;
        height: 80px; 
        width: 100px; 
        background-color: $ub-preview-card-bg;
        img {
          max-width:100%;
          max-height:100%;
          object-fit: contain;
        }
        &:hover{
          background-color: $ub-white; 
        }
      }
      .asset-item.selected {
        border: solid 3px $ub-selected-border-color; 
        background-color: $ub-selected-bg-color; 
        padding: 0px;
        &:hover {
          background-color: $ub-hover-selected-bg-color; 
        }
      }  
  }
}

.asset-library__action {
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
}

.asset-library__lower-area {
  display: flex;
  width: 100%; 
  justify-content: space-between; 
  margin-top: 20px;
  .asset-library__return {
    button {
      background-color: white; 
      padding: 5px; 
      border: solid 1px $borderGray;
    }
  }
}

