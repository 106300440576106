.color-swatch {
  margin: 0;
  padding: 3px;

  &.color-none {
    background-color: whitesmoke;
  }

  .color-none-icon {
    position: absolute;
	  // margin: 0 auto;
    padding: 0;
    text-align: center;
    z-index: -1;
  }
}

.btn-color-swatch {
  &.selected {
    border: none;
    box-shadow: none;
  }
}

