input.art-name-input:focus::-webkit-input-placeholder {
    outline: none !important;
}

input.art-name-input:focus:-moz-placeholder {
    outline: none !important;
}

/* Firefox 18- */
input.art-name-input:focus::-moz-placeholder {
    outline: none !important;
}

/* Firefox 19+ */
input.art-name-input:focus:-ms-input-placeholder {
    outline: none !important;
}

/* oldIE ;) */