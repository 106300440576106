.EditPreview {
  display: none;
  .mobile-preview-background {
    display: flex; 
    justify-content: center; 
    height: 50px; 
    width: 100%;
    padding: 5px; 
    background-color: lightgray;
  }
  
  .mobile-preview-image {
    max-height: 100%;
    max-width: 100%; 
  }
  
  .mobile-preview__text {
    display: flex; 
    justify-content: space-between; 
  }
}

@media screen and (max-width: $bp-desktop) {
  .EditPreview {
    display: inherit; 
    height: 70px; 
    margin-bottom: 10px;
  }
}