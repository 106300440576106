.ChromaDrawer {
  width: 100%;
  height: 100%;
}

.chromaStyle {
  width: 100%;
}

.logo-image {
  height: 100% !important;
  max-height: 40px !important;
  width: 100% !important;
  max-width: 120px !important;
  margin-left: -35% !important;
}

@media (min-width: 1040px) and (max-width: 1399px) {
  .logo-image {
    margin-left: -35% !important;
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .logo-image {
    margin-left: -40% !important;
  }
}

@media (min-width: 1600px) {
  .logo-image {
    margin-left: -60% !important;
  }
}

.ChromaColors {
  .chroma-colors-container {
    // margin-top: 10px;

.dropdown-toggle-color-swatch {
      //border-radius: 3px !important;
      //margin-top: 40px;
}
  }
}

.dropdown-toggle-color-swatch-pattern {
  height: 28px !important;
  width: 28px !important;
  border-radius: 4px;
}

.chroma-colors-spacing-placeholder-1 {
  width: 100%;
  height: 30px;
}

.chroma-colors-spacing-placeholder-2 {
  width: 100%;
  height: 20px;
}