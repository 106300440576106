
.asset-library__navagation {
  width: 100%;
  height: 350px;
  overflow: auto; 
  border: solid 1px $borderGray;
    #asset-library__nav-tree-container {
      background-color: lightblue;
      overflow: auto;
    }
  #logo_nav {
    padding: 20px;
  }
  ul {
    list-style-type: none;
    margin-left: 27px;
    transition: height 1s ease-in-out; 
      li {
        color : $ub-black; 
        font-size: 22px; 
        cursor: pointer;
        &:hover {
          color: $ub-hover-color; 
        }
      }
      .asset-library_endpoint {
        color: $ub-preview-card-border;
        &.searching{
          span{
            display: none
          }
        }
        &.selected{
          span{
            display: inherit; 
          } 
          color: $linkBlue; 
          b {
            color: $ruskinGold; 
          }
        }
      }
      .nested {
        visibility: hidden;
        height: 0px; 
      }
      .active {
        display: block; 
      }
  }
}

.asset-library-nav {
  display: flex; 
  &.nav-closed.searching{
    visibility: hidden;
    height: 0px; 
  }
}