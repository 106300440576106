.pattern-texture {
  width: 75px;
  height: 75px;
  background-color: $ub-pattern-bg;
  background-size: cover;

  &:hover,
  &:focus,
  &:active {
    transition: .15s;
    background-color: $ub-pattern-bg-hover;
  }
}

.pattern-color-pickers-container {
  display: flex;
  height: 100px;
  justify-content: flex-start;
  align-items: flex-start;
}

.pattern-color-picker {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}
