// desktops
@media (min-width: 1600px) {
  .test-page.test-products-page {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    .no-results-warning {
      font-size: 10px;
      font-style: italic;
      margin: 100px auto;
      color: $ub-grey--700;
    }
  }

  .design-tab-heading {
    font-family: $ub-font-slate-std-medium;
    color: $ub-black;
    font-size: 22px;
  }

  .loading-view {
    font-size: 10px;
    margin: 100px auto;
    color: $ub-grey--700;
  }

  .main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 15px;
  }

  .header-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .selected-design-name {
    font-family: $ub-font-forza-medium;
    color: $ub-tab-underline;
    font-size: 14px;
    padding-right: 2% !important;
  }

  .card-design-tab {
    height: 299px !important;
    width: 230px !important;
    color: $heading-grey;
    font-family: $ub-font-forza-medium;

    &:hover {
      cursor: pointer;
      border-radius: 5px;
      background: $mainWhite;
      border: 2px solid $ub-selected-design;
    }
  }

  .card-design-active {
    height: 299px !important;
    width: 230px !important;
    cursor: pointer;
    color: $mainWhite;
    font-family: $ub-font-forza-bold;
    border: 2px solid $ub-selected-design;

    .selected-name {
      background-color: $ub-black;
      line-height: 32px;
    }
  }

  .image-card-designs {
    height: 100% !important;
    max-height: 320px !important;
    width: 100% !important;
    max-width: 210px !important;
  }

  .image-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .design-name {
    line-height: 32px;
  }

  .circle {
    position: absolute;
    text-align: center;
    z-index: 1;
    margin-top: 40%;
    margin-left: 37%;
    width: 65px;
    height: 65px;
    background: $mainWhite;
    border-radius: 50%;
  }

  .selected-design-checkmark {
    position: relative;
    text-align: center;
    z-index: 1;
    margin-top: 8%;
    margin-left: 10%;
  }
}


// laptops
@media (min-width: 1040px) and (max-width: 1599px) {
  .test-page.test-products-page {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    .no-results-warning {
      font-size: 10px;
      font-style: italic;
      margin: 100px auto;
      color: $ub-grey--700;
    }
  }

  .design-tab-heading {
    font-family: $ub-font-slate-std-medium;
    color: $ub-black;
    font-size: 22px;
  }

  .loading-view {
    font-size: 10px;
    margin: 100px auto;
    color: $ub-grey--700;
  }

  .main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 15px;
  }

  .header-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .selected-design-name {
    font-family: $ub-font-forza-medium;
    color: $ub-tab-underline;
    font-size: 14px;
  }

  .card-design-tab {
    height: 230px !important;
    width: 160px !important;
    color: $heading-grey;
    font-family: $ub-font-forza-medium;

    &:hover {
      cursor: pointer;
      border-radius: 5px;
      background: $mainWhite;
      border: 2px solid $ub-selected-design;
    }
  }

  .card-design-active {
    height: 230px !important;
    width: 160px !important;
    cursor: pointer;
    color: $mainWhite;
    font-family: $ub-font-forza-bold;
    border: 2px solid $ub-selected-design;

    .selected-name {
      background-color: $ub-black;
    }
  }

  .image-card-designs {
    height: 100% !important;
    max-height: 320px !important;
    width: 100% !important;
    max-width: 200px !important;
  }

  .image-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .design-name {
    line-height: 32px;
  }

  .circle {
    position: absolute;
    text-align: center;
    z-index: 1;
    margin-top: 35%;
    margin-left: 32%;
    width: 55px;
    height: 55px;
    background: $mainWhite;
    border-radius: 50%;
  }

  .selected-design-checkmark {
    position: relative;
    text-align: center;
    z-index: 1;
    margin-top: 5%;
    margin-left: 5%;
  }
}