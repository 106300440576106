.ViewSelectorCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid transparent;
  max-height: 240px;
  height: 100%;
  transition: border .3s, opacity .3s;
  opacity: .7;
  position: relative;

  &:hover {
    border: 3px solid rgb(209, 209, 209);
  }

  .name {
    position: relative;
    bottom: 4%;
  }

  .img-container {
    width: 100%;
    height: 100%;
    display: grid;

    position: relative;

    .img {
      position: absolute;
      display: block;
      max-width: 80%;
      max-height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &.active {
    border: 3px solid $ub-selected-design;
    opacity: 1;

    &:hover {
      border: 3px solid $ub-selected-design;
    }
  }
}

.view-selector-card-on {
  border: 3px solid rgb(209, 209, 209)
}



@media screen and (max-width: $bp-mobile) {
  .ViewSelectorCard {
    .img-container {
      height: 1px;
      visibility: hidden;
    }

    width: 100%;
  }
}