@import './variables';
@import './animations';
@import './bootstrap-reduced';
@import './globals'; // make sure this is at the end to prevent bootstrap overriding things.
@import './bootstrapOverrides';
@import './tooltip';


// include 3rd party lib css before app css
@import '~rc-slider/assets/index.css';


// Main App Component
@import '../_App';

/* ==========================================================================
  Add individual component imports here.
========================================================================== */

// Layouts / Views
@import '../components/ultimateBuilder/_ultimateBuilder';
@import '../components/myDesigns/_myDesigns';
@import '../components/header/_header';
@import '../components/footer/_footer';
@import '../components/header/customerService/_customerService';
@import '../components/productViews/_productViews';
@import '../components/sidebar/_sidebar';
@import '../components/sidebar/tabContent/_tabPage';
@import '../components/previewCanvasTwo/previewCanvasTwo';
@import '../components/previewStage/_previewStage';
@import '../components/statusPages/notFound404/notFound404';
@import '../components/statusPages/loadingPage/_LoadingPage';

// Global Components
@import '../components/global/baseInputComponent/_baseInputComponent';
@import '../components/global/ultimateModal/ultimateModal';
@import '../components/global/ultimateModal/systemArtModal/systemArtModal';
@import "../components/global/cardToggleButtonGroup/cardToggleButtonGroup";
@import '../components/global/colorSwatch/_colorPicker';
@import '../components/global/colorSwatch/_newColorPicker';
@import '../components/global/colorDropdown/_colorDropdown';
@import '../components/global/colorSwatch/_colorSwatch';
@import '../components/global/colorSwatch/_newColorSwatch';
@import '../components/global/colorSwatch/_colorSwatchStitch';
@import '../components/global/colorSwatch/_colorSwatchGroup';
@import '../components/global/colorSwatch/_newColorSwatchGroup';
@import '../components/global/colorSwatch/_colorSwatchStitchGroup';
@import '../components/global/accordion/_Accordion';
@import '../components/global/slider/_slider';
@import '../components/global/table/_table';
@import '../components/global/checkbox/_checkbox';
@import '../components/global/checkbox/_checkboxStitching';
@import '../components/global/optionHeading/OptionHeading';
@import '../components/global/emptyState/emptyState';
@import '../components/global/fileUploader/fileUploader';
@import '../components/global/previewRenderGenerator/previewRenderGenerator';
@import '../components/global/helpBubble/helpBubble';
@import '../components/global/helpBubble/sideBarTooltip';
@import '../components/global/TabHeader/TabHeader';
@import '../components/header/mobileHeaderActions/_addToCartButton';
@import '../components/header/mobileHeaderActions/leadTime';

// Sidebar Components
@import '../components/sidebar/navigation/navigation';
@import '../components/sidebar/tabs/AddDesign/AddDesign';
@import '../components/sidebar/tabs/StyleDetails/StyleDetails';
@import '../components/sidebar/tabs/colors/colors';
@import '../components/sidebar/tabs/fabrics/_fabrics';
@import '../components/sidebar/tabs/playerRoster/_playerRoster';
@import '../components/sidebar/tabs/addText/_addText';
@import '../components/sidebar/tabs/addArt/_addArt';
@import '../components/sidebar/tabs/uploadArt/uploadArt';
@import '../components/sidebar/tabs/uploadArt/artGallery/ArtGallery';
@import '../components/sidebar/tabs/addArt/AssetLibrary/assetLibrary';
@import '../components/sidebar/tabs/uploadArt/artGallery/artGalleryCard/_ArtGalleryCard';
@import '../components/sidebar/tabs/addText/dragToDesign/dragToDesign';
@import '../components/sidebar/tabs/addText/addTextDrawer/addTextDrawer';
@import '../components/sidebar/tabs/AddToDesignButton/AddToDesignButton';
@import '../components/sidebar/tabs/editDrawer/_EditDrawer';
@import '../components/sidebar/tabs/editDrawer/editPreview/EditPreview';
@import '../components/sidebar/tabs/editDrawer/editTextColorPicker/editTextColorPicker';
@import '../components/sidebar/tabs/editDrawer/editTextColorPicker/textColorSelector/textColorSelector';
@import '../components/header/titleAndDescription/titleAndDescription';
@import '../components/sidebar/tabs/colors/patterns/patterns';
@import '../components/sidebar/tabs/colors/advancedColorDrawer/_advancedColorDrawer';
@import '../components/sidebar/tabs/colors/ChromaColors/_chromaColors';
@import '../components/sidebar/tabs/colors/ChromaColors/_chromaColors';
@import '../components/sidebar/tabs/colors/ChromaPatterns/_chromaPatterns';
@import '../components/sidebar/tabs/AddDecoration/AddDecoration.scss';
@import '../components/sidebar/tabs/AddDecoration/DecorationDetails/DecorationDetails.scss';
@import '../components/sidebar/tabs/editDrawer/editRotation/_editRotation';
@import '../components/sidebar/tabs/editDrawer/editBottomButtons/_editBottomButtons';
@import '../components/sidebar/tabs/colors/stitchingColor/_StitchingColor.scss';
@import '../components/sidebar/tabs/uploadArt/addUploadArt';
@import '../components/sidebar/tabs/editDrawer/editArtFlip/EditArtFlip';
@import '../components/sidebar/tabs/uploadArt/artGallery/MyUploads';
@import '../components/sidebar/tabs/playerRoster/_SizeRoster';
//@import "../components/sidebar/tabs/colors/upgradeCheckbox/upgradeCheckbox";
@import "../components/global/multiDropdown/multiDropdown";
@import "../components/sidebar/tabs/summaryTab/summaryTab";
@import "../components/sidebar/tabs/addText/locationSummary/locationSummary";
@import "../components/sidebar/tabs/editDrawer/editTextValue/editTextValue.scss";
@import "../components/sidebar/tabs/AddDecoration/AddDecorationButton/AddDecorationButton.scss";
@import "../components/sidebar/tabs/editDrawer/editArtName/_editArtName";
@import "../components/sidebar/tabs/editDrawer/editCustomArtColors/_editCustomArtColors";

// Preview / Stage / ViewSelector Components
@import '../components/decorationPriceTotal/DecorationPriceTotal';
@import '../components/productViews/viewSelector/viewSelectorCard/ViewSelectorCard';
@import '../components/previewStage/viewActive/ViewActive';
@import '../components/productViews/viewSelector/ViewSelector';

// Test Pages
@import '../components/testPages/testProductsPage';


// Modal Components
@import '../components/global/ultimateModal/ultimateModal';
@import '../components/global/ultimateModal/saveModal/_saveModal';
@import '../components/global/ultimateModal/imageUploadModal/_ImageUploadModal';
@import '../components/global/ultimateModal/missingRoster/missingRoster';
@import '../components/global/ultimateModal/deleteModal/deleteModal';
@import '../components/global/ultimateModal/quantityModal/quantityModal';
@import '../components/global/ultimateModal/loadFromStorage/loadFromStorage';
@import '../components/global/ultimateModal/deleteModal/deleteModal';
@import '../components/global/ultimateModal/infoModal/infoModal';
@import '../components/global/ultimateModal/shareModal/shareModal';
@import '../components/global/ultimateModal/duplicateModal/duplicateModal';
@import '../components/global/ultimateModal/editUserArtDataModal/EditUserArtDataModal';
@import '../components/global/ultimateModal//sublimatedProductDetailsModal/sublimatedProductDetailsModal.scss';
@import '../components/global/ultimateModal/changeDesignModal/changeDesignModal';

//Sublimated Products Page
@import '../components/sublimatedProductsDesigns/sublimatedProductDesigns';

//Fonts
@font-face {
  font-family: 'Forza';
  src: url('./Fonts/Forza-Medium.otf') format("truetype");
}
@font-face {
  font-family: 'Forza-light';
  src: url('./Fonts/Forza-Light.ttf') format("truetype");
}
@font-face {
  font-family: 'Forza-bold';
  src: url('./Fonts/Forza-Bold.ttf') format("truetype");
}
@font-face {
  font-family: 'Slate Std';
  src: url('./Fonts/slate-pro-light-417.otf') format("truetype");
}
@font-face {
  font-family: 'Slate-std-med';
  src: url('./Fonts/slate-std-medium.otf') format("truetype");
}

@font-face {
  font-family: 'Slate Std Regular';
  src: url('./Fonts/slate-pro-book.otf') format("truetype");
}

@font-face {
  font-family: 'Slate Std Light Italic';
  src: url('./Fonts/slate-pro-light-italic.otf') format("truetype");
}

@font-face {
  font-family: 'Slate Std Bold';
  src: url('./Fonts/slate-std-bold.otf') format("truetype");
}