/*
Desktop defined by default 
then Tablet (max-width: $bp-desktop)
then mobile (max-width: $bp-tablet) */
   
  .views {
    flex: 1 1 20%;
    min-width: 100px;
    height: 100%;
    display:grid;
    margin-right: 25px;
    padding: 20px 0px;
    align-items: center;
    .views-content {
      display:grid;
      justify-items: center;
      height: 100%;
      max-height: 800px;
      grid-template-rows: 1fr auto;
    }
    .dec {
      align-self: flex-start;
    }
    .sel {
      align-self: flex-end;
      height: 100%;
      width: 100%;
      display: grid;
      justify-content: center;
    }
  }

@media screen and (max-width: $bp-desktop){
 
  .views {
    flex: 1 0 0;
    margin-right: 0;
    padding: 0; 
    .dec {
      display: none; 
    }
  }

  .views-content {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
  }
}

