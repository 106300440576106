.cart-button {
    font-family: $ub-font-forza-bold;
    color: $ub-white;
    font-size: 18px;
    border-radius: 5px;
    opacity: 1;
    height: 34px;
    width:276px;
    background-color: $ub-add-to-cart-button-color;
    text-align: center;
    padding-top: 1.5px;
}
.cart-button.addCart{
    padding-top: 4px;
}

.cart-button:hover {
    cursor: default;
    color: white !important;
}
:disabled{
    background-color: grey;
}