.preview-canvas-two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .canvas-sizer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // TODO: Alan going to 99 gets funky, need to find a way to get canvas to go to edge 100% of parent div
    height: 98%;
    width: 98%;
  }
}


