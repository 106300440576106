.patterns-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .custom-disabled {
    opacity: .5;
    pointer-events: none;
  }

  .pattern-card {
    margin-right: 15px;
    width: 33px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;

    .pattern-group-margin-top {
      display: flex;
      margin-top: 15px;
      justify-content: flex-start;
      align-items: center;
      width: 300px;
      height: 25px;
    }

    .pattern-group {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 300px;
      height: 25px;
    }

    .pattern-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 33px;
      white-space: normal;
      height: 25px;
      font-size: 12px;
      font-family: $ub-font-forza-medium;
    }
  }
}

.pattern-list-container {
  width: 300px;
  height: auto;

  .pattern-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: wrap;
    height: 600px;
    overflow: scroll;
  }

  .pattern-container {
    width: 65px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
    margin-right: 10px;
    margin-bottom: 5px;

    .pattern {
      width: 65px;
      height: 65px;
      background-color: $ub-pattern-bg;
      background-size: cover;

      &:hover,
      &:focus,
      &:active {
        transition: .15s;
        background-color: $ub-pattern-bg-hover;
      }
    }


    .pattern-selected-border {
      border: 3px solid #72B9F1;
    }

    .pattern-no-border {
      border: none;
    }

    .pattern-title {
      display: flex;
      word-wrap: break-word;
      white-space: normal;
      justify-content: flex-start;
      text-align: left;
      width: 33px;
      align-items: flex-start;
    }
  }
}

.advanced-layer-container {
  width: 100%;
  margin-top: 15px;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  .pattern-drawer-container {
    margin: 0px 5px 15px 0px;
    width: 33px;
    height: 105px;
  }

  .advanced-layer-title {
    white-space: normal;
    width: 33px;
    height: 16px;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .advanced-layer-footer {
    width: 33px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
    top: -15px;
    font-size: 12px;
  }

  .advanced-layer-card {
    width: 33px;
    height: 33px;
    border: 3px solid #D2D2D2;
    background-size: cover;
  }
}

.pattern-drawer-header {
  margin: 15px 0px 15px 0px;
}


.pattern-texture {
  width: 28px !important;
  height: 28px !important;
  background-color: $ub-pattern-bg;
  background-size: cover;
  border: 1px solid $ub-color-swatch-border;
  border-radius: 4px;
  // border: 3px solid #D2D2D2;

  &:hover,
  &:focus,
  &:active {
    transition: .15s;
    background-color: $ub-pattern-bg-hover;
  }
}

.advanced-layer-caret-container {
  width: 8px;
  height: 8px;
  background-color: #d2d2d2;
  position: absolute;
  top: 19px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-caret {
  width: 0px;
  height: 0px;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid #6D767E;
}

.pattern-drawer-caret-container {
  width: 15px;
  height: 15px;
  background-color: #d2d2d2;
  position: relative;
  top: -15px;
  left: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}