.modal-card-height {
    height: 90px;
    width: 70px;

}

.modal-card-padding {
    padding-bottom: 4px;
}

.modal-card-img-top {
    height: 100%;
    max-height: 90px;
}

.btn-black {
    background-color: black !important;
    color: white;
}

.btn-black:hover {
    background-color: black !important;
    color: lightgray;
}

.brand-image {
    height: 100% !important;
    max-height: 40px !important;
    width: 100% !important;
    max-width: 120px !important;
}

.card-modal-hover {
    &:hover {
        cursor: pointer;
        transition: 0.35s;
        border-color: $ub-hover-color;
        background-color: lighten($ub-hover-color, 35%);
    }
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.modal-heading-style {
    font-family: "Slate-std-med";
    font-size: 24px;
    line-height: 22px;
    color: #2e2e2e;
}

.modal-heading-design-name {
    font-family: "Forza-bold";
    // font-weight: 300 !important;
    font-size: 18px;
    line-height: 14px;
    color: #2e2e2e;
}

.modal-heading-design {
    font-family: "Slate Std";
    font-weight: 500;
    font-size: 12px;
    // letter-spacing: 0.03em;
    line-height: 18px !important;
    color: #868686;
}

.text-list {
    font-family: "Slate Std";
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #000;

}

.text-button {
    font-family: "Saira";
    font-weight: bold;
    font-size: 18px;
    color: #fff;
}

.btn-customize {
    font-weight: 600;
    // pointer-events: none;
    padding-top: 5px;
    font-size: 18px;
    top: 50;
    left: 10;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #707070;
    background-color: black !important;
    color: white;
}

.btn-customize:hover {
    font-weight: 600;
    // pointer-events: none;
    padding-top: 5px;
    font-size: 18px;
    top: 50;
    left: 10;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #707070;
    background-color: black !important;
    color: lightgrey;
}

.btn-master-customize {
    font-weight: 600;
    // pointer-events: none;
    // padding-top: 5px;
    font-size: 12px;
    top: 50;
    left: 10;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #707070;
    background-color: black !important;
    color: white;
}

.btn-master-customize:hover {
    font-weight: 600;
    // pointer-events: none;
    // padding-top: 5px;
    font-size: 12px;
    top: 50;
    left: 10;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #707070;
    background-color: black !important;
    color: white;
}