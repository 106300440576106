/*
Desktop defined by default 
then Tablet (max-width: $bp-desktop)
then mobile (max-width: $bp-tablet) */

.decoration-price-total {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  // margin: 10px 0 0 0;
  // padding: 10px;
  // width: 128px;
  // min-height: 120px;
  color: $ub-white;
  font-family: Arial, Helvetica, sans-serif;

  .title {
    font-size: 13px;
    margin-bottom: 5px;
    padding: 5px 0px 0px 0px;
  }
  
  .price-container {
    display: flex;
    flex-direction: row;
    justify-self: center;
    grid-template-columns: auto auto auto;

    .dollarSign {
      font-size: 18px;
    }

    // .price {
    //   font-size: 40px;
    // }

    // .cents{
    //   font-size: 40px;
    // }
    // .per-piece {
    //   font-size: 10px;
    //   align-self: flex-end;
    //   margin: 0 -12px 10px 0;
    //   padding: 0;
    //   text-align: left;
    // }

    .last-col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .decoration_secondline {
    // display: flex;
    font-size: 9px;
    // flex-direction: row;
    // .peritem {
    //   padding-right: 35px;
    // }
    .note {
      cursor: pointer;
      // align-items: right;
      // padding-left: 10px;
    }
  }
}
