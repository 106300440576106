.mobile-location-picker {
  display: none;
  margin-top: 10px; 
}

.AddTextDrawer {
  .option-body {
    input {
      margin: 10px 0px ;
    }
    .message-box {
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: $bp-desktop) {
  .mobile-location-picker {
    display: inherit;
  }

  .add-text-messagebox {
    margin-top: 10px;
  }
}

