.modal-dialog-infomodal{
  position: fixed !important; 
  bottom: 7.5% !important;
  left: -1% !important;
  // transform: translate(-50%) !important;
 }
.item-total-modal{
  position: fixed !important;
  bottom: 4% !important;
  left: 30% !important;
  margin-top: 0 !important;
  
  .modal-content{
    border-radius: 5px !important;
  }
  .modal-body{
    padding: 0;
  }
}

.leadtime-modal{
  position: fixed !important;
  bottom: 4% !important;
  left: 12% !important;
  margin-top: 0 !important;

  .modal-content{
    border-radius: 5px !important;
  }
  .modal-body{
    padding: 0;
  }
}

.snackbar.show {
  visibility: visible; 
  -webkit-animation: fadein 0.5s, fadeout 0.5s;
  animation: fadein 0.5s, fadeout 0.5s;
}

.snackbar.hide {
  -webkit-animation: fadeout 0.5s;
  animation: fadeout 0.5s;
}

.test-toast {
  visibility: hidden;
  height: 0px;
}

.test-toast.show {
  visibility: visible;
  height: 20px; 
}

.replace-artwork-header {
  text-align: center; 
  font-size: 16px; 
  padding: 5px; 
  margin-bottom: 15px; 
}

.ra-choice {
  height: 230px; 
  width: 140px; 
  text-align: center; 
  margin-bottom: 20px; 
  .ra-image {
    border-left: 1px solid #707070;
    border-top: 1px solid #707070;
    border-right: 1px solid #707070;
    background-color: #BFBCBC; 
    height: 150px; 
  }
  .ra-prompt{
    height: 35px; 
    font-size: 14px; 
    padding: 10px; 
  }
  .ra-no {
    border: solid #BFBCBC 1px; 
  }
  .ra-yes {
    background-color: #000000;
    color: white;  
  }
  :hover {
    cursor: pointer;
  }
  .ra-message {
    font-size: 16px; 
    padding: 0 22px; 
  }
}
.ra-end-message {
  font-size: 10px; 
  text-align: center;
}

.h-title {
  display: grid;
  grid-template-columns: 1fr ;
  position: relative;
  .close {
    position: absolute;
    top: 10px;
    right: 15px;
  }
  h5 {
    padding: 0px;
    margin: 0px;
    text-align: center;
  }
}

.original-cancel-mobile {
  display: none;  
}

.ra-cancel{
  padding: 10px; 
  width: 152px;
  border: solid 1px darkgrey;
}


@media screen and (max-width: $bp-desktop) {
  .original-cancel-full {
    position: absolute; 
    visibility: hidden;
    width: 0px;
  }

  .original-cancel-mobile {
    display: flex;  
    flex-direction: column;
    margin: 0 auto; 
  }

  .file-upload-logic {
    height: 100%; 
    display: flex; 
    flex-direction: column; 
    justify-content: flex-end; 
    font-size: 12px;    
  }
  

}
