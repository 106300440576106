
/*
  Desktop defined by default 
  then Tablet (max-width: $bp-desktop)
  then mobile (max-width: $bp-tablet) */
  
.preview {
  background-color: $ub-grey--100;
  flex: 1 1 100%;
  height: 100%
}

.preview__image {
  max-width: 500px;
  max-height: 620px;
  width: 100%;
}


@media screen and (max-width: $bp-desktop) {
  .preview {
    pointer-events: none;
  }

  .preview__image {
    max-height: 80vh;
  }
}
