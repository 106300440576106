.text-color-selector-container {
  display: flex;
  justify-content: right;
  //align-items: center;*/
  flex-wrap: wrap;
  width: inherit;
  min-height: 50%;
  //width: 270px;
  width: 100%;
  height: auto;
  // margin: 0px 5px 0px 5px;
}

@media (min-width: 1040px) and (max-width: 1399px) {
  .text-color-selector-container {
    display: flex;
    justify-content: right;
    align-items: center;
    flex-wrap: wrap;
    width: inherit;
    min-height: 50%;
    width: 100%;
    height: auto;
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .text-color-selector-container {
    display: flex;
    justify-content: right;
    align-items: center;
    flex-wrap: wrap;
    width: inherit;
    min-height: 50%;
    width: 100%;
    height: auto;
  }
}

@media (min-width: 1600px) {
  .text-color-selector-container {
    display: flex;
    justify-content: right;
    align-items: center;
    flex-wrap: wrap;
    width: inherit;
    min-height: 50%;
    width: 100%;
    height: auto;
  }
}

.chroma-color-selector-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: inherit;
  height: 50px;
  width: 270px;
  margin-bottom: 15px;
}

.pattern-chroma-color-selector-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: inherit;
  // height: 50px;
  // width: 370px;
  // margin-bottom: 15px;
}

.text-color-hint-heading {
  color: $ub-grey--400;
  font-size: 7px;
  font-family: $ub-font-forza-medium;
}

.text-color-name-subheading {
  color: $ub-black;
  font-size: 10px;
  font-family: $ub-font-forza-medium;
}

.color-label-padding {
  // padding-right: 70px;
  // margin-right: 10px;
}