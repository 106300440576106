.padding-bottom-buttons {
    padding-left: 30px;
}

.custom-cancel-button {
    width: 100%;
    height: 35px;
    text-align: center;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    transition: .15s;
    cursor: pointer;
    outline: 1px solid $ub-grey--100;
    border-radius: 3px;
    font-size: 20px;
    font-family: $ub-font-slate-std;
    text-align: center;
    box-shadow: 0px 3px 6px $ub-button-shadow;

    &:hover {
        box-shadow: 3px 5px 6px $ub-button-shadow;
    }
}

.custom-save-button {
    width: 100%;
    height: 35px;
    text-align: center;
    color: $ub-white;
    background-color: $ub-black;
    padding-top: 10px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $ub-black;
    transition: .15s;
    cursor: pointer;
    outline: 1px solid $ub-grey--100;
    border-radius: 3px;
    font-size: 20px;
    font-family: $ub-font-slate-std;
    text-align: center;
    box-shadow: 0px 3px 6px $ub-button-shadow;

    &:hover {
        color: $ub-grey--100;
        box-shadow: 3px 5px 6px $ub-button-shadow;
    }
}