.service {
  align-content: center; 
  .service__line {
    display: flex;
    flex-direction: row;
  }
  .service__text {
    padding-left: 5px;
    margin-top: 10px; 
    justify-content: center;
    align-items: center;
    width: 278px;
    font-size: 12px;
  }
  
  .service__name {
    display: none; 
  }

  .user-headphones{
    height: 50px; 
    width: 60px;
    fill: black;
  }

}

@media screen and (max-width: $bp-desktop) {
  .service {
    a:hover {
      text-decoration: none;
    }
    .service__text{
      display: none; 
    }
    .service__name {
      display: flex; 
      text-align: center; 
    }
  
    .user-headphones{
      height: 32px; 
      width: 32px;
      fill: #707070; 
    }
  }

 
  
}