.SaveModal{
  display:grid;
  grid-template-columns: 200px auto;
  grid-template-rows: auto auto;

  .preview-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    .size {
      height: 250px;
      width: 198px;
      background-color: #857979;
      border: 1px solid #707070;
    }
    .innerContainer{
      height:345px !important;
    }
    .preview-buttons {
      margin-top: 3px;
      z-index: 10;
    
      button {
        font-size: 11px;
        color: black;
      }
      .active-btn {
        background-color: #e2f1fc;
        border-color: #71b9f1;
        box-shadow: none;
      }
    }

    .preview-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: 1500ms;
    }
  }
  .inputs {
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10px;
    .intro {
      text-align: center;
      .sub-title { 
        font-size: 16px;
      }
      .message {
        font-size: 12px;
      }
    }
    .input {
      // margin-top: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  .note{
    font-size: 10px;
  }
  .mockup-saved{
    font-size: 12px;
    color: green !important;
    margin-top: 5px;
    text-align: center;
   }
 
  .actions {
    height: 100%;
    width: 100%;
    grid-column: 1 / -1;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .last-modified {
      font-size: 10px;
    }
    
    .button-container {
      display: flex;
      justify-self: flex-end;
      button {
        min-width: 70px;
        min-height: 35px;
        margin-right:5px;
      }
    }
  }
  h2 {
    font: 13px Helvetica;
    margin-top: 30px;
    text-align: center;
    text-transform: uppercase;
  }

  h2.background {
    position: relative;
    z-index: 1;

    &:before {
      border-top: 2px solid #dfdfdf;
      content: "";
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      width: 25%;
      z-index: -1;
    }

    span {
      /* to hide the lines from behind the text, you have to set the background color the same as the container */
      background: #fff;
      padding: 0 15px;
    }
  }

  h2.double:before {
    /* this is just to undo the :before styling from above */
    border-top: none;
  }

  h2.double:after {
    border-bottom: 1px solid black;
    content: "";
    margin: 0 auto; /* this centers the line to the full width specified */
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    width: 85%;
    z-index: -1;
  }
}
.saveModalPreview{
  display: flex;
}
.imageHeight{
  height:345px;
}
.shareHeight{
  height:355px;
}
.imageButtons{
  width: 200px;
  display: flex;
  flex-direction: column;
}

.btn-outline{
  border: 3px solid black;
}
.btn-outline:hover{
  color: white;
}
.btn-outline-red{
  border: 3px solid #D40001;
  color: #D40001;
}
.btn-outline-red:hover{
  background-color: #D40001 !important;
  border: 3px solid #D40001 !important;
  color: white;
}

.buttonPadding{
  padding-left: 20%;
  padding-right: 20%;
}

.btn-red {
  background-color: #D40001;
  color: white;
 }
 .btn-outline-dark:hover{
 background-color: black;
 }
 .overlay-box {
  position: fixed;
  top: 0;
  left: 0;
  background: #666;
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
}
 .loader {
  position: absolute;
  left: 50%;
  top: 50%;
  right: 50%;
  bottom: 50%;
  z-index: 9999;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #151B24;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add animation to "page content" */
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s
}

@-webkit-keyframes animatebottom {
  from { bottom:-100px; opacity:0 } 
  to { bottom:0px; opacity:1 }
}

@keyframes animatebottom { 
  from{ bottom:-100px; opacity:0 } 
  to{ bottom:0; opacity:1 }
}


@media screen and (max-width: $bp-mobile){
  .SaveModal{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}
