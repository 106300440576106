
.padding-left-color-picker {
    padding-left: 8.5%;
}

// desktops
@media (min-width: 1600px) {
    .padding-left-color-picker {
        padding-left: 5.5%;
    }
}

// laptops
@media (min-width: 1040px) and (max-width: 1599px) {
    .padding-left-color-picker {
        padding-left: 8%;
    }
}