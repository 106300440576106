.quantity-modal {

  .btn-next {
    margin-left: 5px;
  }

  .size-quantity-input-up {
    position: relative;
    top: 22px;
    left: 95px;
    width: 0; 
    height: 0; 
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 7px solid black;
    cursor: pointer;
  }

  .size-quantity-input-down {
    position: relative;
    top: 26px;
    left: 95px;
    width: 0; 
    height: 0; 
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 7px solid black;
    cursor: pointer;
  }

  .custom-input-field {
    width: 100px;
    padding-left: 5px;
    margin-top: 15px;
    height: 35px;
    border: 1px solid #d2d2d2 !important;
  }

}


@media (max-width: $bp-desktop) { 

  .size-quantity-container {
    width: 250px !important;
  }

  .quantity-modal {

      .size-quantity-input-up {
        left: 47px;
      }

      .size-quantity-input-down {
        left: 47px; 
      }
  
  }
}