.dropdown-menu-space-between {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  border-radius: 7px;
  font-size: 14px;
  font-family: $ub-font-slate-std;
  font-weight: bold;
  color: #303030;
  border-color: #d2d2d2;
  width: 100% !important;

  .dropdown-caret {
    float: right;
    // display: flex;
    justify-content: flex-end !important;
    // padding-left: 70%;
    // align-items: center;
  }

  .dropdown-value {
    padding-top: 3px;
    text-align: left;
    // justify-content: flex-start !important;
    // align-items: center;
  }
}

.multi-dropdown {

  .dropdown-title-container {
    display: flex;
    justify-content: flex-start !important;
    // align-items: center;
    color: #303030;
    font-size: 12px;
    width: 100% !important;
  }

  .dropdown-menu-option {
    img {
      width: 100%;
      height: 100%;
      max-height: 45px;
    }

    font-size: 14px;
    font-family: $ub-font-slate-std;
    font-weight: bold;
    color: #303030;
    cursor: pointer;
  }
}

.downward-drop {
  transform: translate3d(0px, 35px, 0px) !important;
}

//desktops
@media (min-width: 1600px) {
  .multidropdown-menu{
    width: 100% !important;
    // height: 300px !important;
    // min-height: 100px !important;
    // height: 50px !important;
    height: auto !important;
    // height: 100% !important;
    // position: absolute !important;
    // overflow: hidden;
    overflow: scroll;
    max-height: 300px !important;
  }
}

//laptops
@media (min-width: 1040px) and (max-width: 1599px) {
  .multidropdown-menu{
    width: 100% !important;
    // height: 300px !important;
    // min-height: 100px !important;
    // height: 50px !important;
    height: auto !important;
    // height: 100% !important;
    // position: absolute !important;
    // overflow: hidden;
    overflow: scroll;
    max-height: 250px !important;
  }
}