.btn-add-decoration {
    width: 100%;
    height: 35px;
    cursor: pointer;
    outline: 1px solid $ub-grey--100;
    border-radius: 3px;
    text-align: center;
    box-shadow: 0px 3px 6px $ub-button-shadow;
}

.btn-add-decoration:hover {
    width: 100%;
    height: 35px;
    cursor: pointer;
    outline: 1px solid $ub-grey--100;
    border-radius: 3px;
    box-shadow: 3px 5px 6px $ub-button-shadow;
}

.button-font-forza {
    font-family: $ub-font-forza-medium;
}

.button-font-slatestd {
    font-family: $ub-font-slate-std;
}