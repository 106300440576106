@mixin custom-art-card {
  background-color: $ub-art-gallery-card-background;
  border: 2px solid $ub-art-gallery-card-border;
 }
 
 .art-gallery-card-options {
  @include custom-art-card;
  height: 82px;
  width: 94px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0px;
  margin: 1px;
 
  img {
  max-width: 89px;
  max-height: 77px;
  }
 }
 
 @media(min-width: 1600px) {
  .art-gallery-card-options {
  height: 82px;
  width: 150px;
 
  img {
  max-width: 120px;
  max-height: 80%;
  }
  }
 
  .art-gallery {
  height: 150px !important;
  width: 240px !important;
 
  img {
  max-width: auto !important;
  max-height: auto !important;
  }
  }
 }
 
 @media(max-width:1230px) {
  .art-gallery-card-options {
  height: 82px;
  width: 64px;
 
  img {
  max-width: 55px;
  max-height: 80px;
  }
  }
 
  .art-gallery {
  height: 82px !important;
  width: 64px !important;
 
  img {
  max-width: auto !important;
  max-height: auto !important;
  }
  }
 }
 
 @media(max-width:780px) {
  .art-gallery-card-options {
  height: 82px;
  width: 50px;
 
  img {
  max-width: 40px;
  max-height: 80px;
  }
  }
 }
 
 @media(max-width:652px) {
  .art-gallery-card-options {
  height: 82px;
  width: 35px;
 
  img {
  max-width: 30px;
  max-height: 80px;
  }
  }
 }
 
 .art-gallery {
  @include custom-art-card;
  height: 115px;
  // width: 155px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin: 3px;
 
  img {
  width: auto;
  min-width: 89px;
  max-width: 129px;
  height: auto;
  min-height: 77px;
  max-height: 107px;
  }
 }
 
 
 
 .art-edit-mode {
  position: relative;
  bottom: 30px;
  right: -69px;
  z-index: 1;
  width: 0px;
  height: 0px;
 
  button {
  &.btn {
  &.btn-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin: 0;
  padding: 4px;
  }
  }
  }
 
 }
 
 .active-art-gallery-card {
  background-color: #e2f1fc;
  border-color: #71b9f1;
  box-shadow: none;
 }
 
 .custom-card {
  @include custom-art-card;
  height: auto;
  width: 100%;
 }
 
 .art-edit {
  margin-left: auto;
  margin-right: auto;
  align-self: auto;
 }
 
 .flip-icon {
  align-content: flex-start;
 }
 
 
 
 .align-svg {
  display: flex;
  align-items: center;
  justify-content: center;
 }
 
 .artImage {
  height: 100%;
  width: 100%;
  max-height: 100px;
  max-width: 100px;
  padding-top: 8px;
  padding-bottom: 8px;
 }

 
 .flipImageX {
  transform: scaleX(-1);
 }
 
 .button-back-to-library {
  // justify-content: center;
  padding-top: 50px;
  font-family: $ub-font-forza-bold;
  font-size: 12px;
  color: $ub-grey--400;
 }

 .flip-rotate-img{
  transform: var(--angle) scaleX(-1);
}

 .rotate-img{
  transform: var(--angle);
}