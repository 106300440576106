.component__base {
  position: relative;
}

.is-disabled .label {
  color: $ub-grey--200;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  width: 100% !important;

  .label {
    font-size: 14px;
  }

  .label--right {
    font-size: 12px;
  }
}

.footer-container {
  font-size: 12px;
  white-space: normal;
  word-wrap: normal;
}