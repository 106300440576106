.d2d-box {
    background-color: #e5e5e5;
    width: 256px;
    max-height: 180px;
    border-radius: 2%;
    border: solid hsl(0, 0%, 44%) 1px;
    margin-top: 10px;
}

.d2d-drag-info {
  width: 256px;
  text-align: center;
}

.d2d-title {
  padding: 5px;
  // margin-bottom: 25px;
  font-size: 16px;
}

.d2d-drag-area {
  height: 70px;
  color: white;
  margin-bottom: 25px;
  border: solid #707070 1px;
  background-color: #9b9b9b;
}

.d2d-or {
  font-size: 16px;
  margin-top: 10px;
}

.d2d-area {
  display: flex; 
  flex-direction: column;
  text-align: center;
  align-content: center;
}

.d2d-foot-message {
  width: 300px;
  margin-top: 15px;
  padding: 10px;
  border: solid lightgray;
}

.d2d-drag-div {
  max-width: 100%;
  pointer-events: none;
  height: 45px;
}

@media screen and (max-width: $bp-desktop) {

  .d2d-area {
    display: none; 
  }
}
