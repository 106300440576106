.decoration-tab-heading {
    font-family: $ub-font-slate-std;
    color: $ub-black;
    font-size: 18px;
}

.col-outline {
    // outline: 2px solid $ub-black;
    margin-left: 2px;
    margin-right: 4px;
}

.btn-decoration {
    border-radius: 4px;
    font-size: 20px;
    font-family: $ub-font-forza-bold;
    box-shadow: 0px 3px 6px $ub-button-shadow;

    &:hover {
        border: 1px solid $ub-black;
        box-shadow: 3px 5px 6px $ub-button-shadow;
        cursor: pointer;
    }
}

.btn-decoration-active {
    border-radius: 4px;
    border: 1px solid $ub-black;
    font-size: 20px;
    font-family: $ub-font-forza-bold;
    cursor: pointer;
    box-shadow: 0px 3px 6px $ub-button-shadow;
}

.btn-decoration-disabled {
    cursor: pointer;
    border-radius: 4px;
    font-size: 20px;
    font-family: $ub-font-forza-bold;
    color: $ub-grey--700;
    border: 1px solid $ub-grey--700;
    background-color: $ub-grey--100;
    box-shadow: 0px 3px 6px $ub-button-shadow;
}

.btn-back-to-decoration {
    font-family: $ub-font-slate-std-medium;
    color: $ub-grey--600;
    font-size: 9px;
}

.arrow-svg {
    padding-bottom: 30px !important;
    padding-top: 4px;
}

.decoration-header-row{
    height: 60px;
}
// desktops
@media (min-width: 1600px) {
    .btn-decoration {
        border-radius: 4px;
        font-size: 22px;
        font-family: $ub-font-forza-bold;
        box-shadow: 0px 3px 6px $ub-button-shadow;

        &:hover {
            border: 1px solid $ub-black;
            box-shadow: 3px 5px 6px $ub-button-shadow;
            cursor: pointer;
        }
    }

    .btn-decoration-active {
        border-radius: 4px;
        border: 1px solid $ub-black;
        font-size: 22px;
        font-family: $ub-font-forza-bold;
        cursor: pointer;
        box-shadow: 0px 3px 6px $ub-button-shadow;
    }

    .decoration-tab-heading {
        font-family: $ub-font-slate-std;
        color: $ub-black;
        font-size: 18px;
    }

    .col-outline {
        // outline: 2px solid $ub-black;
        margin-left: 1px;
        margin-right: 1px;
    }

    .btn-dec-art-active {
        text-align: center;
        font-weight: bold;
    }

    .btn-dec-art {
        text-align: center;
    }

    .btn-decoration-disabled {
        cursor: pointer;
        border-radius: 4px;
        font-size: 22px;
        font-family: $ub-font-forza-bold;
        color: $ub-grey--700;
        border: 1px solid $ub-grey--700;
        background-color: $ub-grey--100;
        box-shadow: 0px 3px 6px $ub-button-shadow;
    }

    .btn-back-to-decoration {
        font-family: $ub-font-slate-std-medium;
        color: $ub-grey--600;
        font-size: 9px;
    }
}

// laptops
@media (min-width: 1040px) and (max-width: 1599px) {
    .btn-decoration {
        border-radius: 4px;
        font-size: 20px;
        font-family: $ub-font-forza-bold;
        box-shadow: 0px 3px 6px $ub-button-shadow;

        &:hover {
            border: 1px solid $ub-black;
            box-shadow: 3px 5px 6px $ub-button-shadow;
            cursor: pointer;
        }
    }

    .btn-decoration-active {
        border-radius: 4px;
        border: 1px solid $ub-black;
        font-size: 20px;
        font-family: $ub-font-forza-bold;
        cursor: pointer;
        box-shadow: 0px 3px 6px $ub-button-shadow;
    }

    .btn-decoration-disabled {
        cursor: pointer;
        border-radius: 4px;
        font-size: 20px;
        font-family: $ub-font-forza-bold;
        color: $ub-grey--700;
        border: 1px solid $ub-grey--700;
        background-color: $ub-grey--100;
        box-shadow: 0px 3px 6px $ub-button-shadow;
    }

    .decoration-tab-heading {
        font-family: $ub-font-slate-std;
        color: $ub-black;
        font-size: 18px;
    }

    .col-outline {
        // outline: 2px solid $ub-black;
        margin-left: 1px;
        margin-right: -2px;
    }

    .btn-dec-art-active {
        text-align: center;
        font-weight: bold;
    }

    .btn-dec-art {
        text-align: center;
    }

    .btn-back-to-decoration {
        font-family: $ub-font-slate-std-medium;
        color: $ub-grey--600;
        font-size: 9px;
    }

}

.padding-row {
    padding: 0 18px;
}

.custom-control-label {
    // added for alignment with the switch
    padding-top: 0.5rem;
    padding-left: 1.5rem;
    // padding-bottom: 0.1rem;
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    height: 2rem;
    width: 3.5rem; // it was 1.75rem before. Sliding way is longer than before.
    pointer-events: all;
    border-radius: 1rem;
}

.custom-switch .custom-control-label::after {
    top: calc(0.25rem + 1px);
    left: calc(-2.25rem + 1px);
    width: calc(2rem - 2px); // it was calc(1rem - 4px) before. Oval is bigger than before.
    height: calc(2rem - 2px); // it was calc(1rem - 4px) before. Oval is bigger than before.
    background-color: #adb5bd;
    border-radius: 2rem; //   it was 0.5rem before. Oval is bigger than before.
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
        transition: none;
    }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(1.5rem); //translateX(0.75rem);
    transform: translateX(1.5rem); //translateX(0.75rem);
}

input[type="checkbox"],
input[type="checkbox"]+label {
    cursor: pointer;
}