.decoration__overview {
  &:not(:last-child) {
    border-bottom: 1px solid $ub-grey--200;
  }
}

.roster-fade-in {
  .roster-data {
    height: 100%;
    max-height: 800px;
    z-index: 0;
  }
}

.decoration__thumbnail {
  &:first-child {
    padding-right: 0;
    margin-right: 2px;
  }
  &:not(:first-child) {
    padding-left: 0;
    margin-left: 2px;
  }
}

.decoration__list {
  padding-left: 15px;
  margin: 0;
}

.roster__download {
  margin-top: 20px;
}

.roster__download-link {
  color: $ub-font-color;
  svg {
    width: 22px;
    height: auto;
    transition: transform 0.3s ease-in-out;
  }
  span {
    padding-left: 0.5em;
  }
  // &:hover {
  //   svg {
  //     transform: scale(1.2);
  //   }
  // }
}

.roster-thin-line {
  border: 1px solid #000;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
}

.tblc-dropdown__parent {
  .dropdown {
    button {
      max-height: 20px;
      margin: 0px;
      margin-right: 0px;
      border-radius: 2px;
      background: white;
      max-width: 60px;
      min-width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px;
      font-size: 12px;

      div {
        display: none;
      }
    }

    .dropdown-menu show {
      text-align: center;
      max-width: 10px;
      color: red;
    }
  }

  .dropdown show {
    div .dropdown-menu show {
      color: red !important;
    }
  }
}

.add-button {
  align-items: center;
}

.notes {
  font-size: 12px;
  font-family: $ub-font-slate-std-medium;
  font-style: italic;
  color: #000000;
  align-items: left;
  opacity: 1;
}

//Desktop
@media (min-width: 1600px) {
  .tbl-table .tbl-cell .tbl-complex .tblc-arrow {
    position: absolute;
    left: 60px;
    cursor: pointer;
  }

  .tbl-table .tbl-cell .tbl-complex select {
    padding: 7px;
    height: 18px;
    border-radius: 2px;
    padding-bottom: 1px;
    padding-top: 1px;
    padding-left: 15px;
    box-shadow: none;
    background: transparent;
    background-image: none;
    // -webkit-appearance: none;
    // -moz-appearance: none;
    // appearance: none;
    margin: 1px;
    cursor: pointer;
  }
}
