//Desktop
@media (min-width: 1600px) {
    .design-brand-image {
        height: 100% !important;
        max-height: 40px !important;
        width: 100% !important;
        max-width: 120px !important;
        margin-left: -35% !important;
    }

    .design-heading-main {
        font-family: $ub-font-forza-bold;
        font-weight: 600;
        font-size: 22px;
        text-align: left;
        color: $ub-black;
    }

    .heading-low {
        font-family: $ub-font-forza-medium !important;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 10px;
        text-align: left;
        display: inline-block;
        color: $ub-black !important;
        line-height: 5px;
    }

    .stock-brand-logo {
        margin-left: -11% !important;
    }
}

//laptop
@media screen and (min-width: 1025px) and (max-width: 1599px) {
    .design-brand-image {
        height: 100% !important;
        max-height: 40px !important;
        width: 100% !important;
        max-width: 120px !important;
        margin-left: -20% !important;
    }

    .design-heading-main {
        font-family: $ub-font-forza-bold;
        font-weight: 600;
        font-size: 22px;
        text-align: left;
        color: $ub-black;
    }

    .heading-low {
        font-family: $ub-font-forza-medium !important;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 10px;
        text-align: left;
        display: inline-block;
        color: $ub-black !important;
        line-height: 5px;
    }

    .stock-brand-logo {
        margin-left: -6% !important;
    }
}


@media (max-width: 1025px) {
    .design-brand-image {
        height: 100% !important;
        max-height: 40px !important;
        width: 100% !important;
        max-width: 120px !important;
        margin-left: -20% !important;
    }
}