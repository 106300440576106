/*
Desktop defined by default 
then Tablet (max-width: $bp-desktop)
then mobile (max-width: $bp-tablet) */

.header {
  height: 60px;
  background-color: $ub-white;
  position: relative;
  z-index: 5;
  border-bottom: 1px solid $ub-grey--100;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .back-to-products-button {
    width: $ub-nav--width;
    height: 100%;
    font-size: 10px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    transition: .15s;
    color: $ub-grey--500;
    transition: color 0.3s ease;
    cursor: pointer;
    .header__chevron--left {
      width: auto;
      height: 18px;
      fill: $ub-grey--500;
      margin: 0 4px;
    }
    &:hover {
      color: $ub-font-color;
      text-decoration: none;
      .header__chevron--left {
        fill: $ub-font-color;
      }
    }
  }

  .header__main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .header__actions{
    display: flex; 
    .info{
      fill: #707070;
      justify-self: center;
      align-self: center;
      margin-right: 10px; 
      cursor: pointer;
      &:hover {
        fill: black;
      }
    }
    
    .mobile__actions{
      display: none;
      text-align: center;
      padding-left: 10px; 
      cursor: pointer;
      svg {
        fill: #707070; 
      }
    }
    .moble__actions__spacer{
      padding: 5px; 
    }

    .service__name {
      text-transform: uppercase;
      color: #707070; 
    }
  }
}



@media screen and (max-width: $bp-desktop) {
  .header { 
    z-index: 0;

    .header__actions{
      .info{
        align-self: flex-start;
      }
    }

    .mobile__actions{
      display: flex !important; 
    }
  }
}