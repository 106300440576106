.test-page.test-products-page {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  .main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
  }

  .no-results-warning {
    font-size: 10px;
    font-style: italic;
    margin: 100px auto;
    color: $ub-grey--700;
  }

  .loading-view {
    font-size: 10px;
    margin: 100px auto;
    color: $ub-grey--700;
  }

  .main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 15px;
  }

  .header-title {}

  .header-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .header-filter-menu {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .header-filter-menu-item {
      margin: 0 0 0 15px;

      &.active {
        font-weight: bold;
        margin: -5px -10px -5px 5px;
        padding: 5px 10px 5px 10px;
        background-color: fade_out($ub-selected-bg-color, 0.2);
        border: 1px solid $ub-selected-border-color;
        border-radius: 4px;
      }
    }
  }

  .header-search-area {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    align-items: flex-end;

    .header-search-input {
      width: 250px;

      input[type=search] {
        padding: 0 5px;
        text-align: center;
      }
    }
  }

  .card-margin {
    margin: 8px !important;
  }

  .card-item {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    height: 180px;
    margin-bottom: 10px;

    &:hover {
      transition: 0.35s;
      border-color: $ub-hover-color;
      background-color: lighten($ub-hover-color, 35%);
      box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2)
    }
  }

}

.test-product-image-loader {

  .preview-image,
  &.preview-image {
    position: relative;
    margin: 0;
    padding: 10px;
    min-height: 100px;
    object-fit: contain;
  }

  .loading-spinner {
    // d-flex flex-column align-self-center align-items-center align-content-center justify-content-center
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    opacity: 0;

    &.show {
      transition-delay: 3s !important;
      transition: 0.3s;
      opacity: 1;
    }

    &.hide {
      transition-delay: 0s !important;
      ;
      transition: 0.1s;
      opacity: 0;
    }
  }

  .img-holder {
    opacity: 1;

    &.loading {
      transition-delay: 0s !important;
      ;
      transition: 0s;
      opacity: 0.2;
    }

    &.loaded {
      transition-delay: 0s !important;
      ;
      transition: 0s;
      opacity: 1;
    }
  }

}

.card-margin {
  margin: 8px !important;
}

.card-hover {
  width: 170px;
  height: 230px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #d6d6d6;

  &:hover {
    transition: 0.15s;
    border-radius: 5px;
    background: #fff;
    border: 2px solid #000;
    cursor: pointer;
    // box-shadow: 0 0 30px rgba(16, 16, 16, 0.6);
    .card-details {
      font-family: "Forza-bold";
      font-weight: 900;
      font-size: 12px;
      line-height: 22px;
      color: #2e2e2e;
    }
  }
}

// .button-wrapper{
//   position: absolute;
//   pointer-events: none
// }
.button-hover {
  font-family: "Forza";
  font-size: 11px;
  line-height: 11px;
  position: absolute;
  z-index: 1;
  // pointer-events: none;
  padding-top: 0px;
  top: 0;
  left: 0;
  background: transparent;
  color: black;
  border: transparent;
  display: none;
  cursor: pointer;
  padding-top: 7px;
}

.button-hover:hover {
  font-family: "Forza";
  font-size: 11px;
  line-height: 11px;
  position: absolute;
  z-index: 1;
  // pointer-events: none;
  padding-top: 0px;
  top: 0;
  left: 0;
  background: transparent;
  color: black;
  border: transparent;
  display: none;
  cursor: pointer;
  padding-top: 7px;
}

.card-hover:hover .button-hover {
  display: block;
  cursor: pointer;
}

.card-hover:hover .custom-button-hover {
  display: block;
  cursor: pointer;
}


.custom-button-hover {
  position: absolute;
  z-index: 1;
  margin-top: 75%;
  font-weight: 600;
  // pointer-events: none;
  padding-top: 5px;
  font-size: 15px;
  top: 50;
  left: 10;
  display: none;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #707070;
  background-color: black !important;
  color: white;
}

// .btn-black:hover {
//   position: absolute;
//   z-index:1;
//   // pointer-events: none;
//   padding-top: 0px; 
//   top: 0;
//     left: 0;
//   display: none;
//   cursor: pointer;
//   background-color: black !important;
//   color: lightgrey;
// }
.card-design {
  height: 150px !important;
  width: 175px !important;

  &:hover {
    cursor: pointer;
    transition: 0.15s;
    border-radius: 5px;
    background: #fff;
    border: 2px solid #000;
  }
}

.image-card-design {
  height: 100%;
  max-height: 85px;
  width: 100%;
  max-width: 85px;
}

.image-center {
  display: block;
  margin-left: auto;
  margin-right: auto
}

.heading-main {
  font-family: "Forza-bold";
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: #000;
}

input::placeholder {
  color: #000 !important;
}

#input-search:focus {
  // outline: none !important;
  box-shadow: none !important;
  background: #efefef !important;
}
.heading-sub {
  font-family: "Slate Std";
  font-weight: lighter;
  font-size: 14px;
  line-height: 16px;
  color: #000;
}

.input-text {
  font-family: "Forza";
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  background: #efefef;
}
.search-button {
  background: #efefef !important;
}
.card-details {
  font-family: "Forza";
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #2e2e2e;

}


@media screen and (max-width: $bp-xl) {
  .test-page.test-products-page {
    .main-header {
      flex-direction: column;
    }

    .header-title {
      font-size: 24px;
    }
  }

  .card-margin {
    margin: 4px !important;
  }

  .card-hover {
    width: 131px;
    height: 190px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #d6d6d6;

    &:hover {
      transition: 0.15s;
      border-radius: 5px;
      background: #fff;
      border: 2px solid #000;
      // box-shadow: 0 0 20px rgba(31, 31, 31, 0.6);
    }
  }

  .card-design {
    height: 140px !important;
    width: 140px !important;
    margin-right: 250px !important;

    &:hover {
      cursor: pointer;
      transition: 0.35s;
      border-color: $ub-hover-color;
      background-color: lighten($ub-hover-color, 35%);
    }
  }
}

.loading-view {
  font-size: 10px;
  margin: 100px auto;
  color: $ub-grey--700;
}

.no-results-warning {
  font-size: 10px;
  margin: 100px auto;
  color: $ub-grey--700;
}

@media screen and (max-width: $bp-mobile) {
  .test-page.test-products-page {
    .header-title {
      font-size: 24px;
    }

    .header-menu {
      flex-direction: column;
      align-items: center;

      .header-menu-item {
        margin: 2px 2px 0 2px;
      }
    }
  }

  .card-margin {
    margin: 2px !important;
  }

  .card-hover {
    height: 400px !important;
    width: 270px !important;

    &:hover {
      cursor: pointer;
      transition: 0.35s;
      border-color: $ub-hover-color;
      background-color: lighten($ub-hover-color, 35%);
    }
  }
}