/* ==========================================================================
   opacityFadeIn - fades in element opacity in from 0% to 100%;
   ========================================================================== */

@-webkit-keyframes opacityFadeIn {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0.33;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes opacityFadeIn {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0.33;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes opacityFadeIn {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0.33;
  }
  100% {
    opacity: 1;
  }
}
@keyframes opacityFadeIn {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0.33;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  -webkit-animation: .6s .2s forwards opacityFadeIn;
  animation: .6s .2s forwards opacityFadeIn;
}

.fade-in--children {
  opacity: 0;
  -webkit-animation: .6s .2s forwards opacityFadeIn;
  animation: .6s .2s forwards opacityFadeIn;
  @for $i from 1 to 20 {
    &:nth-child(#{$i}) {
      animation-delay: #{(($i / 20) + 's')};
    }
  }
}

/* ==========================================================================
   MoveOptionIN - fades in element opacity in from 0% to 100% and position element from bottom to top;
   ========================================================================== */


@keyframes moveOptionIn {
  0% {
    transform: translate(0, 100%);
    opacity: 0;
  }
  50% {
    transform: translate(0, 70%);
    opacity: .33;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

/* ==========================================================================
   float - floats element up and down Y-axis
   ========================================================================== */

@keyframes float {
  50% {
     transform: translateY(15px);
  }
}

/* ==========================================================================
   scale - scales element x/y from 1 to 1.1
   ========================================================================== */

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}


@media screen and (max-width: $bp-desktop) {
  .fade-in {
    opacity: 1;
    animation: none;
  }
  .fade-in--children {
    opacity: 1;
    animation: none; 
  }
}
