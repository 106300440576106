.footer {
  background-color: $ub-footer-color;
  height: 51px;

  .saveandshare {
    font-family: $ub-font-slate-std;
    color: $ub-grey--300;
  }

.saveBtn{
  padding-right: 5px;
  padding-left: 3px;
  padding-top: 3px;
}

 .savetext{
  padding-top: 14%;
  
  }
  @media only screen and (min-width: 1500px) and (max-width: 1700px) {
    .savetext{
    padding-top: 11%;
    }
  }


  @media (min-width: 1600px)and (max-width: 2000px)  {
    .saveBtn {    
      padding-left: 59px;
    }
  }

  @media (min-width: 1400px)and (max-width: 1600px)  {
    .saveBtn {
      // display: none;
      // visibility: hidden;
      padding-left: 30px;
    }
  }
  @media (min-width: 1040px) and (max-width: 1399px) {
    .saveBtn {
      padding-left: 12px;
    }
  }
    @media (min-width: 2000px)  {
      .saveBtn {
        padding-left: 116px;
      }
    }
 
  #design {
    font-size: 10px;
  }

  .footer_decoration {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3em 0em 3em 20em;

    .dec {
      align-self: flex-start;
      justify-content: space-between;
    }

    .addtocart {
      width: 20em;
      padding: 0px 0px 0px 25px;
    }
  }

  .footer_help {
    color: white;
    padding-left: 15em;

    .footer_help_needhelp {
      margin-top: 15px;
      margin-bottom: 0px;
    }

    .footer_help_chatwithus {
      margin-bottom: 15px;
      font-size: 12px;
    }
  }

 
}


.row-padding {
  padding-top: 8px !important;
}

.vertical-line {
  border-left: 1px solid #CFCFCF;
  height: 34px;
}

.saveButton {
  height: 20px;
  width: 19px;
}

.btn-primary-round {
  border-radius: 0.5em;
  background-color: #94989E;
}

.btn-primary-round:hover {
  background-color: rgba(116, 116, 116, 0.824);
  /* Green */
  color: rgb(6, 6, 6);
}

.saveBtn:focus {
  outline: none !important;
  box-shadow: none;
}

.cartBtn:focus {
  outline: none !important;
  box-shadow: none;
}

.saveBtn:active {
  transform: translateY(4px);
}

.savebutton-padding {
  margin-left: 15px;
  // margin-top: 4px;
}

.d-hotline.h-btn {
  height: 0px;
}

.chat-row {
  line-height: 3px !important;
}

.item-total{
  margin-top: 6px;
  color: #CFCFCF;
  font-size: 10px;
  font-family: $ub-font-slate-std;
}

.value {
  font-family: 'Forza-bold';
  font-size: 18px;
  color: white;
}

.item-total-content {
  justify-content: center;
  padding-top: 8px;
  padding-right: 0px;
}

.item-data {
  text-align: right;
  font-family: $ub-font-slate-std;
  font-size: 15px;
}

.stock-popup-header {
  font-size: 12px;
  text-align: right;
  padding: 5px 0px 0px 0px;
  font-family: $ub-font-slate-light-italic;
}

.stock-tooltip-note {
  font-size: 12px;
  font-family: $ub-font-slate-light-italic;
  padding-left: 0px;
}

.item-summary {
  font-size: 12px;
  font-family: $ub-font-slate-std-medium;
  padding: 5px 0px 0px 0px;
}

.leadtime-summary {
  font-size: 12px;
  text-decoration: underline;
  font-family: $ub-font-slate-std-medium;
  padding: 5px 0px 0px 0px;
}

.leadtime-email {
  text-decoration: underline;
  color: #45abe3;
}

.tooltip-header {
  padding: 15px;
  background-color: #F8F8F8;
}

.stock-item-total {
  text-align: right;
  font-size: 15px;
  font-family: $ub-font-slate-bold;
}

.stock-tooltip-footer {
  padding: 15px;
}

.stock-details {
  padding-right: 0px;
}

.custom_tooltip {
  width: 520px;
  padding: 0px !important;
  opacity: 1 !important;
}

.__react_component_tooltip::after {
  border-top-width: 20px !important;
  margin-bottom: -15px !important;
}

.item-summary-table {

  padding: 5px;

  .tbl-table {

   .tbl-cell{
    &.tbl-dark {
      background-color: transparent;
      border: none !important;
    }
   }

    .tbl-row {
      height: 22px;
      background-color: white !important;
    }

    .tbl-row-header {
      display: none;
    }

    .tbl-col-0 {
      width: 45%;
      text-align: left;
      font-size: 12px;
      text-transform: uppercase;
      font-family: $ub-font-slate-std-medium;
    }

    .tbl-col-1 {
      width: calc(55/300*100%);
      text-align: left;
      font-size: 12px;
      font-family: $ub-font-slate-std;
    }

    .tbl-col-1::after {
      content: " Color";
    }

    .tbl-col-2 {
      width: calc(25/300*100%);
      text-align: left;
      font-size: 12px;
      font-family: $ub-font-slate-std;
    }

    .tbl-col-3 {
      width: calc(80/300*100%);
      text-align: right;
      font-size: 12px;
      font-family: $ub-font-slate-std;
    }
    .tbl-col-3::after{
      content: " per piece";
    }
  }
}

.next-step {
  color: $ub-grey--300;
  font-size: 10px;
  font-family: $ub-font-slate-std;
  padding-top: 14px;
}

@media (min-width: 0px) and (max-width: 999px) {
  .footer {
    display: none;
    visibility: hidden;
  }
}
@media (min-width: 1980px){
  .item-total{
    font-size: 14px;
    margin-top: 4px;
  }
  .value{
    font-size: 21px;
  }
  .next-step{
    font-size: 14px;
    padding-top: 11px;
  }
  .footer{
    height:53px;
  }
}